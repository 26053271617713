#services h1 {
  text-align: center;
}
#services p {
  text-align: center;
}

.service__cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: flex-start;
  gap: 20;
  padding: 50px;

  /* margin: 100px 0; */
}

.service__card {
  width: 32%;
  /* height: 900px; */
  background-color: var(--bg-color);
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.service__card .header {
  max-height: 500px;
  animation: content-loading 2s linear infinite;
  overflow: hidden;
}

.service__card .body {
  padding: 20px;
  text-align: left;
}
.service__card .body p {
  text-align: left;
  margin: 10px 0;
}

.service__card .footer {
  /* padding: 20px; */
  border: 2px solid black;
  /* background-color: var(--color-darkolive); */
}

.service__card .footer a {
  color: var(--footer-text);
  color: black !important;
  display: flex;
  /* background-color: var(--color-darkbrown); */
  font-size: 16pt;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.service__card .learn-more {
  color: var(--info-link);
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .service__cards {
    justify-content: space-around;
    /* gap: 0px; */
  }
  .service__card {
    width: 45%;
    max-width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .service__cards {
    width: 100%;
    display: flex;
    gap: 20;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    margin-bottom: 100px;
  }

  .service__card {
    width: 90%;
    max-width: 600px;
  }
}

@media screen and (max-width: 572px) {
  .service__card {
    max-width: 400px;
  }
}
