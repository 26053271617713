.policy__container {
  /* max-width: 1080px; */

  margin: 50px 0;
  padding: 100px;
}

@media screen and (max-width: 992px) {
  .policy__container {
    padding: 15px !important;
  }
}
