/* MODAL */

#modal_wrapper {
  width: 100%;
  height: 110vh;
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0 !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

#modal_wrapper {
  /* display: none; */
}
#modal {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  /* align-items: center; */
  justify-content: center;
}
#modal_content {
  width: 90%;
  max-width: 800px;
  min-height: 200px;
  max-height: 80vh;

  background-color: var(--nav-bg);

  position: absolute;

  overflow: hidden;
  overflow-y: auto;

  top: 50px;
}
.modal-filter {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.457);
}
