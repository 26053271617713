/* HEADER */
.checkout-header {
  width: 100%;
  margin: 15px 0;

  /* height: 300px; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-header {
  text-align: center;
  font-size: var(--font-14);
}
.link-tree {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-tree-item {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.link-tree-item a {
  margin: 0 5px;

  font-size: var(--font-12);
}

/* BODY */
.checkout-information {
  width: 100%;
  max-width: 650px !important;
  padding: 5px;

  padding-bottom: 50px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-it {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shipping-note {
  min-height: 150px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.shipping-info {
  width: 100%;
  padding: 20px;
  border: 1px solid var(--input-border);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-evenly;
}

/* INFORMATION */

form {
  width: 100%;
}

.pad-10 {
  padding: 10px;
}

.input-container {
  width: 100%;
  margin: 10px 0;

  height: 60px;

  border: 1px solid var(--input-border);
  border-radius: 10px;

  overflow: hidden;
  /* padding: 10px; */

  display: flex;
  align-items: center;

  text-align: left;
}

.select-input {
  width: 100%;
  height: 55px;
  display: flex;

  position: relative;

  justify-content: space-between;
  /* flex-direction: column; */
  align-items: center;
}

.inputs {
  width: 100%;
  height: 100%;

  padding-left: 10px;
}

.input-row {
  display: flex;
}
.input-info {
  width: 100%;
  height: 20px;

  padding-left: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */

  text-align: left;
}

.input-info p {
  font-size: var(--font-10);
}

.input-col {
}

.select-options {
  width: 100%;
  min-height: 100px;

  background-color: var(--bg-color);
  color: var(--font-14);

  position: absolute;
  top: 55px;

  border: 1px solid var(--input-border);
  border-radius: 0 0 10px 10px;
}

.select-options ul {
  padding: 0;
}

.select-options li {
  width: 100%;

  /* border: 1px solid red; */
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 5px;
}

.select-label {
  font-size: var(--font-12);
  margin-left: 10px;
}

.select-icons {
  display: flex;
  align-items: center;
}

/* NAVIGATION */
.checkout-getaway {
  /* width: 100%; */
  width: 70%;

  margin: 50px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-checkout-getaway {
  width: 100%;
}
.payment-supported {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* CHECKOUT INFO */

#order_summary {
  background-color: rgb(246, 246, 246);

  padding: 15px !important;
}
.checkout-summary {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* background-color: var(--matt); */
}

.total-summary {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: 25px 0;
}

#total__item {
  border-top: 1px solid #262626;
  padding-top: 10px;
}

.total-item {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

/* PAYMENT */
.form-stacker {
  width: 100%;
  border: 1px solid var(--input-border);

  border-radius: 10px 10px 0 0;

  overflow: hidden;
}
.form-accordion {
  width: 100%;
  /* height: 70px; */

  border: 1px solid;
  border: 1px solid var(--matt);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form-accordion-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--input-border);
}

.form-accordion:last-child {
  border-bottom: none;
}

.form-accordion-body {
  width: 100%;
  height: 200px;
}

.form-accordion_inner {
  padding: 15px 50px;
  /* height: 400px; */

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  background-color: lightgray;
}

.form-accordion_inner p {
  text-align: center;
}

.container__img {
  margin-bottom: 20px;
}

.bankcard-options {
  display: flex;
  align-items: center;
  border: none !important;
}
.bankcard-img {
  width: 50px;
  height: 50px;

  /* border: 1px solid var(--matt); */
  margin: 0 5px;
  border: none;

  overflow: hidden;
}

@media screen and (max-width: 999px) {
  .checkout-getaway {
    width: 100%;
    flex-direction: column-reverse;
  }

  .checkout-header {
    padding-top: 50px;
  }

  .link-tree-item {
    margin: 0;
  }

  .center-it {
    margin-top: 60px;
  }

  #order_summary {
    position: fixed;
    top: 60px;
    left: 0;
  }

  .translate-Y {
    transform: translateY(-200%);
  }
}

@media screen and (max-width: 572px) {
  .container__img {
    width: 40px !important;
  }
}
