.quantity__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantity-counter {
  width: 100%;
  height: 70px;
  /* margin-right: 10px; */

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  /* padding: 5px; */

  text-align: center;

  margin: 0;
}

.hover__btn {
}
.quantity__container .add-to-cart {
  width: 45%;
  height: 75px !important;
  margin: 0;
  padding: 0;

  background-color: var(--footer-bg);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0 2px 2px 0;
}

.add-bag-btn {
  color: var(--footer-text);
  font-size: var(--font-14);

  border-radius: 5px !important;
}

/* PROCESS PAYMENT */

.err-window {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.err-window div {
  display: flex;
  align-items: center;
  padding: 40px 0;
}

.process-payment {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 35px;
}

.process-payment p {
  position: relative;
  top: -30px;
}

#process_payment_img {
  width: 250px;
  height: 250px;
}

.err-icon {
  color: tomato;
  font-size: 24pt !important;
}

#err_el {
  font-size: 16pt !important;

  text-align: center;
  margin: 20px 0;
}
