@import url("./header.css");

@import url("./shop/shop.css");
@import url("./accordion/accordions.css");
@import url("./home/home.css");
@import url("./cards/cards.css");
@import url("./blogs/blogs.css");
@import url("./gens/imgs.css");
@import url("./gens/forms.css");
@import url("./gens/btns.css");
@import url("./checkout/checkout.css");
@import url("./gens/layout.css");
@import url("./gens/modal.css");
@import url("../js/components/policy/policies.css");
@import url("../js/components/shop/cart/notification/cart_v.css");
@import url("../js/components/shop/details/productdetails.css");
@import url("./businessopportunity/businessopportunity.css");
@import url("./shop/cart/checkout.css");
@import url("./shop/cart/cart.css");
@import url("../js/components/shop/_globalShop.css");
/* @import url("./product-details/details.css"); */

@import url("./footer.css");

/* GENERALS */

* {
  box-sizing: border-box !important;
  /* overflow-y: scroll !important; */
}

body {
  margin: 0;
  background-color: white !important;
  overflow-x: hidden !important;
}

button {
  border: none;
  background-color: transparent;
  color: black;

  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

li {
  list-style: none;
  box-sizing: border-box;
}

label {
  cursor: pointer;
}

input {
  border: none;
  background-color: transparent;
}

.nav-list {
  /* background-color: var(--color-darkolive) !important; */
  height: 100%;
  margin: 0;
}

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  /* margin-top: 150px; */
}

/* EDITOR*/
#editor {
  height: 80vh;
  overflow-y: scroll;
}

.ql-editor {
  height: 65vh;
}

/* Animation */
.translate-up {
  transform: translateY(-200%);
}

.info-link {
  color: var(--info-link) !important;
}

.font-10 {
  font-size: var(--font-10) !important;
}

.content-is-loading {
  animation: loading-content 2s linear infinite !important;
}

/* CALL TO ACTION */
#call_to_action {
  position: fixed;
  z-index: 9999999999999;
  bottom: 50px;
  right: 50px;
  padding: 20px;
  border-radius: 10px;

  font-size: 16pt;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.56);

  /* width: 50px; */

  background-color: rgba(107, 179, 251, 0.362) !important;
}

#call_to_action:hover {
  background-color: rgba(107, 179, 251, 0.722) !important;
}

@keyframes loading-content {
  0% {
    background-color: white;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: white;
    /* background-image: linear-gradient(to right, whitesmoke, lightgray); */
  }
}

#loading_res {
  width: 100%;
  height: 100vh;

  position: fixed;
  z-index: 9999999999999;
  animation: loader 3s linear infinite;
}

@keyframes loader {
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

/* END */

.is-desktop {
  display: flex !important;
}
.is-mobile {
  display: none !important;
}

.img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.icon-md {
  font-size: 24pt;
}

.icon-sm {
  font-size: 24pt !important;
  font-weight: bold;
}

/* FONT SIZE */
h1 {
  font-size: 36pt;
  font-weight: bold;
  /* font-family: "Lobster Two", cursive; */
  /* font-family: "Comfortaa", cursive !important; */
}

h2 {
  font-size: 28pt;
  font-family: "Open Sans", sans-serif;
}

p {
  font-size: 18pt;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

svg {
  cursor: pointer !important;
}

button,
a,
input {
  font-family: "Open Sans", sans-serif;
}
/* QUIZ */
.take__quiz {
  position: fixed;
  z-index: 9999999;
  bottom: 50px;
  right: 100px;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 100px;

  color: white;
  background-color: #b4c195;

  border-radius: 10px;
}

.take__quiz span {
  font-size: 16pt;
}

.take__quiz-icon {
  font-size: 16pt;
  border: 1px solid whitesmoke;
  border-radius: 50%;
}

.rotate-open {
  transform: rotate(180deg);
}

@media screen and (max-width: 992px) {
  .is-desktop {
    display: none !important;
  }
  .is-mobile {
    display: flex !important;
  }

  .take__quiz {
    right: 40px;

    height: 80px;
  }
}

@media screen and (max-width: 572px) {
  .translate-left {
    transform: translateX(0%) !important;
  }

  #call_to_action {
    bottom: 0;
    left: 0;
    width: 100%;

    border-radius: 10px 10px 0 0;
  }

  .is-desktop {
    display: none !important;
  }
  .is-mobile {
    display: flex !important;
  }

  .take__quiz {
    right: 0px;
    border-radius: 0;
    height: 80px;
    bottom: 0;
    width: 100%;
  }

  h1 {
    font-size: 30pt !important;
  }

  h2 {
    font-size: 24pt !important;
  }

  p {
    font-size: 18pt !important;
    line-height: 1.5;
  }
}
