@import url("./filters/filters.css");
@import url("./paginate/paginate.css");
@import url("./products.css");

#shop {
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 80px;
  padding-bottom: 100px;

  /* align-items: center; */
}

#filters {
  width: 25%;
  /* margin-right: 20px; */
}

#filters .sort__header {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;

  justify-content: space-between;
}

#shop_front {
  width: 65%;

  /* align-items: center; */
}

#shop_front .header {
  height: 60px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#shop_front .header button {
  width: 100px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid whitesmoke;
  border-radius: 5px;
}

.filter__btn {
  margin: 0 10px;
  text-transform: capitalize;
}

#shop_front .body {
  display: flex;
  flex-wrap: wrap;

  /* justify-content: sp; */
  gap: 10px;
}

#pagination_el {
  padding-top: 20px !important;
}

#sort_select {
  width: 100%;
  height: 50px;
  border: none;
  border: 1px solid whitesmoke;
  margin: 10px 0 20px 0;
}

/* Style the options */
select option {
  /* Add your desired styles */
  background-color: #fff;
  color: #333;
  font-size: 16px;
}

/* Style the selected option */
select option:checked {
  /* Add your desired styles */
  background-color: #f2f2f2;
}
.notification__products {
  width: 100%;
  height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.notification__products h3 {
  width: 50%;
  line-height: 1.5;
  text-align: center;

  font-weight: bold;
}

.notification__products p {
  font-weight: 400;
}

.sort_ {
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  /* flex-direction: row; */
}

@media screen and (max-width: 992px) {
  #shop_front .body {
    position: relative;
    justify-content: space-between;
  }

  .sort_ {
    margin-top: 20px;
  }
  #filters {
    width: 100%;
    height: 100vh;
    position: fixed;
    padding-bottom: 100px;
    top: 70px;
    left: 0;
    z-index: 9999;
    /* display: none; */
    transform: translateX(-200%);
    background-color: #fff;
    padding: 15px;
    padding-bottom: 50px;
  }

  .sort_ {
    flex-direction: column;
    align-items: flex-start;
  }

  #filters .sort__header {
    max-width: 100%;

    /* padding: 0 15px; */
  }

  #filters .header {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 15px;
  }

  #shop_front {
    width: 95%;
  }
}

@media screen and (max-width: 572px) {
  #filters {
  }
}
