#pd__ .product-wrapper {
}
#pd__ {
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;

  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
#pd__ .left__item {
  width: 60%;
  /* min-height: 100vh; */
  padding: 5px;

  box-sizing: border-box;

  /* border: 1px solid red; */
}
#pd__ .right__item {
  width: 35%;
  /* min-height: 100vh; */
  padding: 15px;

  box-sizing: border-box;

  /* border: 1px solid red; */
}

#pd__ .bullet-point {
  list-style: disc;
  padding: 10px 0;
  border-bottom: 1px solid whitesmoke;
}

/* Images */
.pd__active-product {
  width: 100%;
  height: 450px;
  /* height: 600px; */
  /* height: 100vh; */

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  box-sizing: border-box;

  pointer-events: visiblePainted;

  /* border: 1px solid red; */
}

/* DETAILS */
#pd__ .review-stamp {
  display: flex;
  align-items: center;
}
#pd__ .review-icons {
  display: flex;
  align-items: center;
}
#pd__ .star-icon {
  background-color: var(--bg-color);
}
#pd__ .icon-sm {
  font-size: var(--font-14);
}

#pd__ .product-id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 20px 0;
}

#pd__ .product-id h2 {
  font-size: 24pt;
  text-transform: uppercase;
}
#pd__ .product-id p {
  font-size: 16pt;
}

#pd__ .product-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 30px 0;
}

#pd__ .product-description h3 {
  font-size: 18pt !important;
  text-transform: uppercase;
}

#pd__ .flavor-details {
  margin: 30px 0;
}

#pd__ .flavor-btn {
  border: 1px solid var(--matt-2);
  border-radius: 10px;
  margin: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.pd_full__accordion {
  width: 100% !important;
  max-width: 100% !important;

  border: none !important;
}
.pd_b__bottom {
  border-bottom: 1px solid whitesmoke !important;
}

@media screen and (max-width: 992px) {
  #pd__ {
    flex-direction: column;
  }
  #pd__ .left__item {
    width: 100%;
    padding: 0;
  }
  #pd__ .right__item {
    width: 100%;
  }
}

@media screen and (max-width: 572px) {
}
