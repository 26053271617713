footer {
  width: 100%;
  min-height: 250px;

  background-color: var(--footer-bg);
  color: var(--footer-text);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  padding: 20px;
}
#footer {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

#footer p {
  font-size: 16pt !important;
}
.footer-col {
  width: 30%;
  height: 250px;

  /* border: 1px solid red; */
  margin: 10px;
  padding: 10px;
  text-align: left;
}

.footer-col p {
  text-align: left !important;
}

#footer-policy {
  display: flex;
  justify-content: space-around;
  align-items: center;

  text-align: center;

  color: var(--footer-text);
}

#footer-policy p {
  font-size: 14pt !important;
}

.policies {
  /* color: var(--footer-text); */
}

.policy-link {
  margin: 0 8px;
  color: var(--footer-text);
}

.footer-item {
  padding: 0;
}

.footer-item li {
  margin: 10px 0;
}

.footer-link {
  font-size: var(--font-12);
  color: var(--footer-text);
}

@media screen and (max-width: 789px) {
  #footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-col {
    width: 100%;
    height: auto;
    max-width: 700px;
  }

  .footer-col p {
    text-align: left;
  }

  .policies {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .policies a {
    width: 100%;
    padding: 10px;

    border-bottom: 1px solid var(--matt);

    text-align: left;
  }

  .footer-link {
    font-size: var(--font-14);
  }

  #footer-policy {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
}
