/* MODAL */

#quiz__modal {
  width: 100%;
  height: 110vh;
  position: fixed;
  z-index: 9999999999999;
  left: 0;
  top: 0 !important;

  display: flex;

  /* align-items: center; */
  justify-content: center;
}

#quiz__modal-content {
  width: 80%;
  max-width: 550px;
  height: 70%;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;
  padding: 15px;

  border-radius: 10px;
  background-color: white;
  /* justify-content: center; */
}

.quiz__modal-header {
  width: 100%;
  text-align: right;
  padding: 10px;
}

.close__quiz-close-btn {
  font-size: 24pt;
}
.quiz__modal-filter {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.457);
}

.quiz__modal-cards {
  width: 90%;
  max-width: 550px;
  height: 55%;
  /* max-height: 65%; */
  padding: 15px;
  margin-top: 0px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  align-items: flex-start;

  overflow-y: auto;
}

.quiz__modal-card {
  width: 95%;
  /* height: 120px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
  text-align: center;

  border-radius: 10px;
  border: 1px solid lightgray;
}

.quiz__modal-card:hover {
  border: 1px solid rgb(69, 130, 139);
}

.quiz__modal-card p {
  margin: 0;

  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;

  /* font-size: 18pt; */

  border-radius: 50%;

  /* border: 1px solid; */
}
.quiz__modal-card h3 {
  /* margin-top: 0; */
  margin: 10px;
  font-size: 18pt;
}

@media screen and (max-width: 572px) {
  #quiz__modal-content {
    width: 100%;
    height: 100%;
    margin: 0;

    border-radius: 0;
  }

  .quiz__modal-cards {
    height: 44%;
  }
}
