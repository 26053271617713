#write_block {
  width: 100%;
  max-width: 900px;
  padding: 10px;
  margin: 10px 0 100px 0;

  // display: block;
  // margin-left: auto;
  // margin-right: auto;
  // position: relative;

  .tooltit__header {
    width: 100%;
    height: 100vh;

    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.29);

    #writing_block-information {
      width: 40%;
      max-width: 572px;
      // height: 60%;
      height: fit-content;
      padding: 20px 20px;

      margin-top: 50px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.29);

      .start__writing {
        width: 100%;
        height: 50px;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;

        .close_toolkit_header-close {
          font-size: 1.8rem;
        }

        .close_toolkit_header-close:hover {
          transform: scale(0.9);

          // width: ;
          // height: 100%;

          border-radius: 50px;
          background-color: whitesmoke;
        }

        .start__writing-btn {
          background-color: transparent;
          border: none;
          .start__writing-icon {
            font-size: 1.6rem !important;
          }
          .start__writing-icon:hover {
            transform: scale(0.9);

            // width: ;
            // height: 100%;

            border-radius: 50px;
            background-color: whitesmoke;
          }
        }
      }

      #writing_block_info_form {
        padding-top: 0px;

        .editor__btn-save {
          width: 200px;
          height: 40px;

          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0;
          border: 1px solid lightgray;
          border-radius: 5px;

          font-size: 1.3rem;

          span {
            margin: 0 10px;
          }
        }

        .input__container {
          display: flex !important;
          flex-direction: column !important;
          justify-content: flex-start !important;
          margin-bottom: 15px !important;

          label {
            font-size: 1.6rem !important;
            position: static;
          }

          #writing_inputs-img {
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          #title {
            border: none;
            border-bottom: 1px solid lightgray;
            height: 45px;
            padding: 0 15px;
            font-size: 1.4rem;
            // font-weight: bold;
            margin: 20px 10px;
          }

          #title:focus {
            border: none;
          }

          #description {
            border: none;
            border-bottom: 1px solid lightgray;
            height: 45px;
            padding: 0 15px;
            font-size: 1rem;
            // font-weight: bold;
            margin: 20px 10px;
          }

          #img_cover {
            width: 100%;
            height: 100%;

            object-fit: contain;
          }
        }
      }
    }
  }

  @media screen and (max-width: 572px) {
    .tooltit__header {
      #writing_block-information {
        width: 80% !important;
        max-width: 572px;
        height: fit-content;
        padding: 20px 0;
        // height: 300px;
        // max-height: 60%;
        gap: 5px;
        justify-content: center;

        #writing_block_info_form {
          padding-top: 0px;
          width: 80%;

          .input__container {
            display: flex !important;
            flex-direction: column !important;
            justify-content: flex-start !important;
            margin-bottom: 15px !important;

            label {
              font-size: 1.2rem !important;
              // position: relative !important;
            }

            #writing_inputs-img {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            #title {
              border: none;
              border-bottom: 1px solid lightgray;
              height: 45px;
              padding: 5px;
              font-size: 1.4rem;
              font-weight: bold;
              margin: 0px 10px;
              margin-top: 10px;
            }

            #title:focus {
              border: none;
            }
          }

          button {
            margin: 0px;
          }
        }
      }
    }
  }

  .header {
    width: 100%;

    display: flex;
    justify-content: flex-end;
  }

  .ql-tooltip {
    top: 0px !important;
    left: 0 !important;
    width: 100% !important;
    transform: none !important;
    // position: relative;
  }

  iframe {
    width: 100%;
    height: 400px;

    overflow: hidden !important;

    div {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ql-toolbar {
    position: sticky;
    z-index: 99999;
    top: 10px;

    background-color: white !important;
    border-radius: 5px 5px 0 0;
  }
  #editor {
    min-height: 40vh;
    overflow-y: visible;

    p {
      text-align: left;
    }

    pre {
      max-width: 80%;
      padding: 20px;
      font-size: 1rem;
    }

    img {
      // width: 100%;
      max-height: 400px !important;

      object-fit: contain;
    }
  }

  #editor_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 50px;
    .editor__btn-save {
      width: 200px;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      border: 1px solid lightgray;
      border-radius: 5px;

      font-size: 1.3rem;

      span {
        margin: 0 10px;
      }
    }
    .editor__btn-publish {
      width: 200px;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      border: 1px solid lightgray;
      background-color: rgb(189, 229, 189);
      border-radius: 5px;

      font-size: 1.3rem;

      span {
        margin: 0 10px;
      }
    }
  }
}

.m_editor__footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.m_editor__footer .editor__btn-save {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
}
.m_editor__footer .editor__btn-publish {
  background-color: #bbff80;
  color: black;
  padding: 10px;
  border-radius: 5px;
}
