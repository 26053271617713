#header_top {
  font-size: 12pt !important;
  text-align: center;
  margin: 0;
  padding: 10px;

  background-color: var(--color-paleyellow);
}

/* NAVBAR */
header {
  width: 100%;
  /* height: 80px; */
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;

  padding: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0 0 2px rgba(0, 0, 0, 0.123);

  background-color: var(--nav-bg) !important;

  transition: ease 0.4s !important;
  /* position: -webkit-sticky; */
}

#logo {
  /* width: 15%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Comfortaa", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important ;

  color: var(--nav-text);

  /* border: 1px solid red; */
}
.logo-title {
  display: flex;
  align-items: center;
  font-size: var(--font-16);
}

#nav {
  /* width: 60%; */
  height: 100%;
  display: flex;

  position: relative;

  /* border: 1px solid red; */
}

.link-toggler {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  align-items: center;

  padding: 0;
  /* justify-content: space-evenly; */
}
.nav-items {
  padding: 0 10px;
  display: flex;
  align-items: center;

  font-size: var(--font-16);
}
.nav-link {
  color: var(--nav-text);
  font-size: var(--font-16);
  margin-right: 5px;
  text-align: left;
}
.nav-options {
  /* width: 20%; */
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;

  /* border: 1px solid red; */
}

.search-container {
  background-color: var(--hover-color);

  /* width: 50%; */
  height: 80%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 10px;
}
.seach-icon {
  color: var(--nav-text);
  font-size: var(--font-16);
}

.search-input {
  height: 100%;
}

.search-input::placeholder {
  color: var(--nav-text) !important;
}

.cart-icon {
  position: absolute;

  top: 10px;
  color: var(--nav-text);
  font-size: var(--font-18);
}

.cart-btn {
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-radius: 20px;
  /* background-color: rgb(77, 77, 77); */
}

.cart-btn:hover {
  background-color: var(--hover-color);
}

#cart_no {
  font-size: 8;
  font-weight: bold;
  color: var(--footer-text);
}

.mobile-btn {
  display: none;
}

.link-options-container {
  position: fixed;
  z-index: 10;
  top: 120px;
  left: 0;

  width: 100%;
  display: flex;
  flex-direction: column;
}

.link-options {
  width: 100%;
  /* min-height: 300px; */

  /* padding: 0; */
  padding: 50px 0;

  display: flex;
  justify-content: center;
  padding-top: 20px;

  background-color: var(--nav-bg);

  /* max-width: 600px; */
}

.link-options li:hover {
  /* border: 1px solid red; */
  /* color: white;
  background-color: var(--color-darkbrown); */
}
.link-options a:hover {
  color: white;
  background-color: var(--color-darkbrown);
  padding: 10px 2px;
}
.nav-filter {
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  margin: 0;
  padding: 0;

  filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.087);
}

.link-options {
  /* display: none; */
}

.link-options ul {
  padding: 0;
}
.link-options li {
  margin: 15px 50px 10px 0;
}

.link-options h3 {
  font-weight: bold;
  font-size: var(--font-16);
  color: var(--nav-text);

  padding: 0;
  margin: 0;
}

.link-options a {
  color: var(--nav-text);
  font-size: var(--font-14);
}

/* SEARCH BAR */
.search-bar {
  width: 100%;
  height: 300px;

  position: fixed;
  z-index: 999;

  top: 0;
  left: 0;

  background-color: var(--nav-bg);
}
.search-bar-header {
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: var(--matt);
}

.cancel-search {
  color: var(--footer-text);
  background-color: var(--footer-bg);
  font-size: 12pt;

  position: relative;
  left: -5px;

  border: 1px solid black;
  /* color: var(--nav-text); */
}

.search-mode {
  width: 45%;

  display: flex;
  justify-content: flex-start;
}

.searching-input {
  width: 100%;
  background-color: var(--nav-bg);
  border-radius: 10px;
}

#search {
  font-size: var(--font-16);
  color: var(--nav-text);
}

#search_bar_body {
  width: 100%;
  height: 80vh;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--nav-bg);

  box-shadow: 0 50px 100px rgba(0, 0, 0, 0.187);

  padding: 10px;
}
#search_bar_sidebar {
  width: 30%;
  height: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid red; */
}

#search_bar_sidebar button {
  padding: 10px 0 0 10px;
  font-size: var(--font-14);
}
#search_bar_main {
  width: 100%;
  height: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  overflow: hidden;
  overflow-y: auto;

  background-color: var(--nav-bg);

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.087); */

  /* border: 1px solid red; */
}

/* SEARCHED PRODUCT */
.searched-product-container {
  width: 250px;
  height: 340px;

  /* border: 1px solid var(--matt-2); */
  padding: 10px;

  box-sizing: border-box;

  margin: 15px;

  overflow: hidden;
}

.searched-product-img {
  width: 250px;
}

/* MOBILE MENU */
.close-menu {
  display: none;
}

/* HIDING */
.hide-nav {
  display: none !important;
}

@media screen and (max-width: 999px) {
  header {
    width: 100%;
    justify-content: space-around;

    margin-bottom: 0;

    box-sizing: border-box;
    /* flex-direction: column; */
  }

  #nav {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    background-color: var(--nav-bg);

    /* display: none; */

    flex-direction: column;
  }

  .link-toggler {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0;
    padding: 0;
  }

  .mobile-navi {
    display: flex !important;
  }

  .close-menu {
    /* position: fixed;
    top: 20px;
    right: 50px; */

    display: flex;
    justify-content: flex-end;
    /* align-items: center; */

    width: 100%;
    /* width: 50px; */
    /* height: 50px; */

    text-align: right;
    padding: 10px 20px;
  }

  .link-options-container {
    position: relative;

    top: 0;
  }

  .link-options {
    flex-direction: column;

    width: 100%;
    /* height: 250px; */
    margin-bottom: 25px;

    border: 1px solid var(--matt);
    padding: 10px;

    overflow: hidden;
    overflow-y: auto;
  }

  .nav-filter {
    display: none;
  }

  #logo {
    /* width: 50%; */
  }

  #search_bar_body {
    flex-direction: column;
  }

  .nav-options {
    display: none;
  }
  .mobile-btn {
    height: 50px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }

  .searched-product-container {
    width: 250px;
    height: 140px;

    display: flex;
    align-items: center;

    /* border: 1px solid var(--matt-2); */
    padding: 10px;

    box-sizing: border-box;

    margin: 15px;

    overflow: hidden;
  }

  .menu {
    font-size: var(--font-24);
    color: var(--nav-text);
  }

  .close-menu-icon {
    font-size: var(--font-28) !important;
    color: var(--nav-text);
  }

  .nav-list {
    height: 100%;
    /* display: flex; */
    flex-direction: column;
    align-items: center;

    /* padding-top: 100px; */

    overflow-y: auto;
    /* justify-content: center; */

    /* justify-content: center; */
    /* align-items: center; */
    /* justify-content: space-evenly; */
  }

  .nav-chervron {
    font-size: 35pt !important;
  }

  .search-mode {
    width: 75%;
  }

  #search_bar_sidebar {
    width: 100%;
    height: 200px;
    align-items: center;
  }
  #search_bar_main {
    justify-content: center;
    width: 100%;
  }

  .nav-items {
    width: 90%;
    padding: 25px 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-link {
    font-size: var(--font-28);
    margin: 0;
  }
}

/* NAVBAR END */

/* SEARCH */
nav {
  /* width: 100%; */
  height: 50px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #fff !important;
}

.search__bar {
  position: absolute;
  top: 66px;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 70px;
  background-color: #fff !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-bottom: 1px solid whitesmoke;
}

.search__bar .header {
  display: flex;
  justify-content: center;

  width: 50%;
}

.search__header {
  display: flex;
  justify-content: center;

  width: 40%;
}

.search__header #search_input {
  width: 100%;
  height: 50px;
  border: none;
  border: 1px solid whitesmoke;
  border-radius: 5px 0 0 5px;

  padding: 0 15px;
}

/* SEARCH */

#search_input {
}

#search_results {
  position: absolute;
  top: 5px;
  z-index: 999999;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: white !important;

  box-shadow: 10px 120px 100px rgba(0, 0, 0, 0.55);

  padding-bottom: 30px;
  /* border: 2px solid blue; */

  overflow: hidden;
  overflow-y: auto;
}

#mobile_search {
  width: 100%;
  padding: 10px;
  position: fixed;
  z-index: 100;

  background-color: #fff;
  top: 0;
}

#search_results .header {
  width: 100%;
  height: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  background-color: white !important;
}

#search_results .body {
  width: 100%;
  height: 90%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  background-color: white !important;

  /* border: 1px solid red; */
  padding: 10px;

  overflow: hidden;
  overflow-y: auto;
}

#search_results .body {
  display: flex;
  flex-wrap: wrap;

  max-width: 1200px;

  /* justify-content: sp; */
  gap: 10px;
}

#search__keyword {
  font-weight: bold;
  text-transform: capitalize;
}

.search__hints {
  border: 1px solid lightgray;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}

@media screen and (max-width: 992px) {
  #search_results .body {
    position: relative;
    justify-content: space-between;
  }

  .search__bar {
    height: 0px;
  }

  .mobile__menu {
    opacity: 0;
    transform: translateX(-200%) !important;

    animation: menu-show 0.5s linear;
  }

  @keyframes menu-show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.3;
    }
    0% {
      opacity: 1;
    }
  }
  @keyframes menu-close {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
    0% {
      opacity: 0;
    }
  }
}

/* SEARCH */
