.accordion__container .header {
  width: 100%;
  max-width: 300px;

  border: 1px solid whitesmoke;
  /* border-b: 1px solid whitesmoke; */

  margin: 0px 0 0 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion__container .body {
  width: 100%;
  max-width: 300px;

  /* max-width: 250px; */
  padding: 10px;

  border: 1px solid whitesmoke;
  border-top: 2px solid lightgray;
  /* border-radius: 0 0 5px 5px; */

  display: flex;
  flex-direction: column;
}

.icon {
  transform: rotate(270deg);
  position: relative;
  z-index: -1 !important;
}

.accordion__container .body .items {
  width: 100%;
  max-width: 250px;
  margin-bottom: 5px;
  line-height: 1.5;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .accordion__container .header {
    max-width: 100%;
  }
  .accordion__container .body {
    max-width: 100%;
  }
  .accordion__container .body .items {
    max-width: 100%;
  }
}
