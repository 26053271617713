#blog_post_container {
  margin: 10px 0 100px 0;
  text-align: left;
}

#blog_post_container p {
  text-align: left;
}

#blog__main {
  width: 100%;
  padding-top: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

#blog__main .left {
  width: 65%;
}
#blog__main .right {
  width: 30%;
}

#blog__post {
  width: 100%;
}

#blog__post .header {
  display: flex;
  flex-direction: column;

  padding-bottom: 20px;
  /* margin-bottom: 20px; */
}

#blog__post h3 {
  text-align: center;
  font-size: 14pt !important;
}

#blog__post h2 {
  /* text-align: center; */
  font-size: 24pt !important;
  margin-bottom: 0px;
}

#blog__post h1 {
  text-align: center;
  font-size: 24pt !important;
  /* font-family: "Comfortaa", system-ui, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
  sans-serif !important; */
}

#blog__post p {
  padding: 10px;
  text-align: left;

  box-sizing: border-box;
}

#learn_more_container {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.learn_more {
  padding: 20px;
  font-size: 16pt;

  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 5px;

  border: 1px solid black;
  /* margin: 100px 0 !important; */
}

.blog__list li {
  padding: 10px 0;
  font-size: 16pt;

  list-style: disc;
}

.author-section {
  display: flex;
  flex-direction: column;

  margin: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}

@media screen and (max-width: 992px) {
  #blog__main {
    flex-direction: column;
    padding-top: 50px;
  }

  #blog__main .left {
    width: 100%;
  }
  #blog__main .right {
    width: 100%;
  }
}
