@import "https://fonts.googleapis.com/css2?family=Lobster+Two:wght@700&family=Open+Sans:wght@300&display=swap";
#header_top {
  text-align: center;
  background-color: var(--color-paleyellow);
  margin: 0;
  padding: 10px;
  font-size: 12pt !important;
}

header {
  z-index: 99;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 0 2px #0000001f;
  background-color: var(--nav-bg) !important;
  transition: all .4s !important;
}

#logo {
  color: var(--nav-text);
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  font-family: Comfortaa, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif !important;
}

.logo-title {
  font-size: var(--font-16);
  align-items: center;
  display: flex;
}

#nav {
  height: 100%;
  display: flex;
  position: relative;
}

.link-toggler {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.nav-list {
  align-items: center;
  padding: 0;
  display: flex;
}

.nav-items {
  font-size: var(--font-16);
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.nav-link {
  color: var(--nav-text);
  font-size: var(--font-16);
  text-align: left;
  margin-right: 5px;
}

.nav-options {
  justify-content: space-around;
  align-items: center;
  height: 100%;
  display: flex;
}

.search-container {
  background-color: var(--hover-color);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  display: flex;
}

.seach-icon {
  color: var(--nav-text);
  font-size: var(--font-16);
}

.search-input {
  height: 100%;
}

.search-input::placeholder {
  color: var(--nav-text) !important;
}

.cart-icon {
  color: var(--nav-text);
  font-size: var(--font-18);
  position: absolute;
  top: 10px;
}

.cart-btn {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.cart-btn:hover {
  background-color: var(--hover-color);
}

#cart_no {
  color: var(--footer-text);
  font-size: 8px;
  font-weight: bold;
}

.mobile-btn {
  display: none;
}

.link-options-container {
  z-index: 10;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: fixed;
  top: 120px;
  left: 0;
}

.link-options {
  background-color: var(--nav-bg);
  justify-content: center;
  width: 100%;
  padding: 20px 0 50px;
  display: flex;
}

.link-options a:hover {
  color: #fff;
  background-color: var(--color-darkbrown);
  padding: 10px 2px;
}

.nav-filter {
  z-index: 0;
  filter: blur(1px);
  background-color: #00000016;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.link-options ul {
  padding: 0;
}

.link-options li {
  margin: 15px 50px 10px 0;
}

.link-options h3 {
  font-weight: bold;
  font-size: var(--font-16);
  color: var(--nav-text);
  margin: 0;
  padding: 0;
}

.link-options a {
  color: var(--nav-text);
  font-size: var(--font-14);
}

.search-bar {
  z-index: 999;
  background-color: var(--nav-bg);
  width: 100%;
  height: 300px;
  position: fixed;
  top: 0;
  left: 0;
}

.search-bar-header {
  background-color: var(--matt);
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
}

.cancel-search {
  color: var(--footer-text);
  background-color: var(--footer-bg);
  border: 1px solid #000;
  font-size: 12pt;
  position: relative;
  left: -5px;
}

.search-mode {
  justify-content: flex-start;
  width: 45%;
  display: flex;
}

.searching-input {
  background-color: var(--nav-bg);
  border-radius: 10px;
  width: 100%;
}

#search {
  font-size: var(--font-16);
  color: var(--nav-text);
}

#search_bar_body {
  background-color: var(--nav-bg);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80vh;
  padding: 10px;
  display: flex;
  box-shadow: 0 50px 100px #00000030;
}

#search_bar_sidebar {
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  height: 100%;
  padding: 20px;
  display: flex;
}

#search_bar_sidebar button {
  font-size: var(--font-14);
  padding: 10px 0 0 10px;
}

#search_bar_main {
  background-color: var(--nav-bg);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.searched-product-container {
  box-sizing: border-box;
  width: 250px;
  height: 340px;
  margin: 15px;
  padding: 10px;
  overflow: hidden;
}

.searched-product-img {
  width: 250px;
}

.close-menu {
  display: none;
}

.hide-nav {
  display: none !important;
}

@media screen and (max-width: 999px) {
  header {
    box-sizing: border-box;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 0;
  }

  #nav {
    z-index: 9999999;
    background-color: var(--nav-bg);
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .link-toggler {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .mobile-navi {
    display: flex !important;
  }

  .close-menu {
    text-align: right;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 20px;
    display: flex;
  }

  .link-options-container {
    position: relative;
    top: 0;
  }

  .link-options {
    border: 1px solid var(--matt);
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .nav-filter {
    display: none;
  }

  #search_bar_body {
    flex-direction: column;
  }

  .nav-options {
    display: none;
  }

  .mobile-btn {
    justify-content: center;
    height: 50px;
    display: flex;
  }

  .searched-product-container {
    box-sizing: border-box;
    align-items: center;
    width: 250px;
    height: 140px;
    margin: 15px;
    padding: 10px;
    display: flex;
    overflow: hidden;
  }

  .menu {
    font-size: var(--font-24);
    color: var(--nav-text);
  }

  .close-menu-icon {
    color: var(--nav-text);
    font-size: var(--font-28) !important;
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: auto;
  }

  .nav-chervron {
    font-size: 35pt !important;
  }

  .search-mode {
    width: 75%;
  }

  #search_bar_sidebar {
    align-items: center;
    width: 100%;
    height: 200px;
  }

  #search_bar_main {
    justify-content: center;
    width: 100%;
  }

  .nav-items {
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    padding: 25px 0;
    display: flex;
  }

  .nav-link {
    font-size: var(--font-28);
    margin: 0;
  }
}

nav {
  z-index: 100;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff !important;
}

.search__bar {
  z-index: 100;
  border-bottom: 1px solid #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  display: flex;
  position: absolute;
  top: 66px;
  left: 0;
  background-color: #fff !important;
}

.search__bar .header {
  justify-content: center;
  width: 50%;
  display: flex;
}

.search__header {
  justify-content: center;
  width: 40%;
  display: flex;
}

.search__header #search_input {
  border: 1px solid #f5f5f5;
  border-radius: 5px 0 0 5px;
  width: 100%;
  height: 50px;
  padding: 0 15px;
}

#search_results {
  z-index: 999999;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  padding-bottom: 30px;
  display: flex;
  position: absolute;
  top: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 10px 120px 100px #0000008c;
  background-color: #fff !important;
}

#mobile_search {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  position: fixed;
  top: 0;
}

#search_results .header {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  display: flex;
  background-color: #fff !important;
}

#search_results .body {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  height: 90%;
  padding: 10px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff !important;
}

#search__keyword {
  text-transform: capitalize;
  font-weight: bold;
}

.search__hints {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}

@media screen and (max-width: 992px) {
  #search_results .body {
    justify-content: space-between;
    position: relative;
  }

  .search__bar {
    height: 0;
  }

  .mobile__menu {
    opacity: 0;
    animation: .5s linear menu-show;
    transform: translateX(-200%) !important;
  }

  @keyframes menu-show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: .3;
    }

    0% {
      opacity: 1;
    }
  }

  @keyframes menu-close {
    0% {
      opacity: 1;
    }

    100% {
      opacity: .5;
    }

    0% {
      opacity: 0;
    }
  }
}

.price__items {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.price__items input {
  width: 100%;
}

#pagination_el {
  place-content: center;
  width: 100%;
  height: 80px;
  display: flex;
}

.paginate__btns {
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin: 5px;
}

.paginate__dots {
  align-items: flex-end;
  height: 40px;
  margin: 1px;
  display: flex;
}

.product {
  border-radius: 5px;
  width: 32%;
  transition: box-shadow .3s;
}

.product-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  height: 550px;
  padding: 10px;
  transition: box-shadow .3s;
  display: flex;
  width: 100% !important;
}

.product-card:hover {
  box-shadow: 0 0 5px #0003;
}

.product-image {
  text-align: center;
  object-fit: contain;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 45%;
}

.product-image img {
  object-fit: contain;
  width: 100%;
  height: 200px;
}

.product-info {
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 80%;
  height: 55%;
  margin-top: 10px;
  display: flex;
}

.product-info .title {
  text-align: left;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px !important;
}

.price {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.description {
  margin-top: 10px;
}

.size-selection {
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  max-width: 200px;
  margin-top: 10px;
  display: flex;
}

.size-selection select {
  font-size: 12pt;
  color: #000 !important;
  background-color: #0000 !important;
  border: none !important;
}

.size-selection label {
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.size-selection select {
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  margin-top: 5px;
}

.product-card .add-to-cart {
  background-color: var(--footer-bg);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  height: 55px !important;
}

.product-card .add-to-cart:hover {
  background-color: #4caf50;
}

.product-card .add-to-cart .add-bag-btn {
  font-size: var(--font-12);
  color: var(--footer-text) !important;
}

@media screen and (max-width: 768px) {
  .product-card {
    width: 32%;
    max-width: 100%;
  }

  .product-info {
    gap: 0;
    width: 100%;
  }

  .product-info .title {
    text-align: left;
    margin-bottom: 0;
    font-size: 14pt !important;
  }
}

@media screen and (max-width: 576px) {
  .product {
    width: 48% !important;
  }

  .product-card {
    max-width: 100%;
    height: 550px;
    width: 100% !important;
  }

  .product-image {
    height: 40%;
  }

  .product-info {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 55%;
    display: flex;
  }
}

#shop {
  justify-content: center;
  width: 100%;
  margin-top: 80px;
  padding-bottom: 100px;
  display: flex;
}

#filters {
  width: 25%;
}

#filters .sort__header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
  display: flex;
}

#shop_front {
  width: 65%;
}

#shop_front .header {
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  display: flex;
}

#shop_front .header button {
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  display: flex;
}

.filter__btn {
  text-transform: capitalize;
  margin: 0 10px;
}

#shop_front .body {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

#pagination_el {
  padding-top: 20px !important;
}

#sort_select {
  border: 1px solid #f5f5f5;
  width: 100%;
  height: 50px;
  margin: 10px 0 20px;
}

select option {
  color: #333;
  background-color: #fff;
  font-size: 16px;
}

select option:checked {
  background-color: #f2f2f2;
}

.notification__products {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  display: flex;
}

.notification__products h3 {
  text-align: center;
  width: 50%;
  font-weight: bold;
  line-height: 1.5;
}

.notification__products p {
  font-weight: 400;
}

.sort_ {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 992px) {
  #shop_front .body {
    justify-content: space-between;
    position: relative;
  }

  .sort_ {
    margin-top: 20px;
  }

  #filters {
    z-index: 9999;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    padding: 15px 15px 50px;
    position: fixed;
    top: 70px;
    left: 0;
    transform: translateX(-200%);
  }

  .sort_ {
    flex-direction: column;
    align-items: flex-start;
  }

  #filters .sort__header {
    max-width: 100%;
  }

  #filters .header {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    display: flex;
  }

  #shop_front {
    width: 95%;
  }
}

.accordion__container .header {
  border: 1px solid #f5f5f5;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 0;
  display: flex;
}

.accordion__container .body {
  border: 1px solid #f5f5f5;
  border-top: 2px solid #d3d3d3;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  display: flex;
}

.icon {
  position: relative;
  transform: rotate(270deg);
  z-index: -1 !important;
}

.accordion__container .body .items {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin-bottom: 5px;
  line-height: 1.5;
  display: flex;
}

@media screen and (max-width: 992px) {
  .accordion__container .header, .accordion__container .body, .accordion__container .body .items {
    max-width: 100%;
  }
}

#hero {
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: 50px;
  display: flex;
}

.hero__img {
  width: 45%;
  height: 100%;
}

.hero__details {
  font-size: var(--font-24);
  color: var(--footer-text);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  padding: 15px;
  display: flex;
  background-color: var(--color-smoothgreen) !important;
}

.hero__details h1 {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 52pt !important;
}

.hero__details p {
  font-size: 24pt;
}

#intro {
  background-color: var(--color-darkbrown);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 150px 0;
  display: flex;
  position: relative;
}

.indent {
  border-left: 40px solid #0000;
  border-right: 40px solid #0000;
  border-bottom: 50px solid var(--color-smoothgreen);
  border-top: 20px solid #0000;
  display: inline-block;
  position: absolute;
  top: -5px;
  transform: rotate(180deg);
}

.intro-body {
  color: var(--footer-text);
  justify-content: center;
  align-items: center;
  width: 80%;
  display: flex;
}

.intro-body p {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24pt !important;
}

.intro-body a {
  color: #eee7e7;
  font-size: 28px;
  font-weight: bold;
  text-decoration: underline;
}

.service-cards {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.service-card {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 35%;
  height: 504px;
  display: flex;
  overflow: hidden !important;
}

.service-img {
  background-color: var(--footer-bg);
  width: 100%;
  height: 100%;
}

.service-filter {
  background-color: #000a;
  width: 33.5%;
  height: 504px;
  position: absolute;
}

.service-details {
  color: var(--footer-text);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 28%;
  padding: 15px;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.service-title-1, .service-details p {
  font-family: Cabin, sans-serif;
}

.service-details p {
  font-family: Montserrat Alternates, sans-serif;
}

#about_me {
  background-color: #fff;
}

.container-flex {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 100px 0;
  display: flex;
}

.about-me-img {
  border: none;
  border-radius: 5px;
  width: 45%;
  height: 500px;
  overflow: hidden;
}

.about-me-details {
  text-align: left;
  width: 40%;
}

#about__me-frame {
  width: 100%;
  height: 100%;
}

.blog-post-container {
  background-color: var(--color-paleyellow);
  width: 100%;
  padding: 100px 0;
}

.newsletter {
  background-color: var(--color-darkolive);
  color: var(--footer-text);
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.blog-post-header {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 10px;
  display: flex;
}

#newsletter_form {
  width: 50%;
}

.select__html {
  color: #fff;
  background-color: #0000;
  width: 100%;
  height: 100%;
  padding: 0 10px 0 0;
  font-size: 16pt;
  border: none !important;
}

select::-webkit-keygen-select {
  padding: 0 20px;
}

#newsletter_form input::placeholder {
  color: var(--footer-text);
}

#newsletter_form input {
  color: var(--footer-text);
  font-size: var(--font-16);
}

.blog-post-body {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.blog-post {
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  height: 850px;
  margin: 0 10px;
  display: flex;
  box-shadow: 0 0 10px #00000035;
}

.blog-post .header {
  width: 100%;
  height: 40%;
  overflow: hidden;
}

.blog-post .body {
  background-color: var(--footer-text);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40%;
  padding: 15px;
  display: flex;
  overflow: hidden;
}

.blog-post .body h2 {
  font-size: 24pt;
}

.blog-post .body p {
  font-size: 16pt;
}

.blog-post .footer {
  background-color: var(--footer-text);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  display: flex;
  overflow: hidden;
  font-size: 16pt !important;
}

.blog-post .footer a {
  text-align: center;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  width: 50%;
  margin-bottom: 10px;
  padding: 15px;
}

.featured-container {
  background-color: var(--color-smoothgreen);
}

.featured-product {
  height: 400px;
}

@media screen and (max-width: 992px) {
  #hero {
    margin-top: 0;
  }

  .hero__img {
    width: 40%;
    height: 100%;
  }

  .hero__details {
    font-size: var(--font-18);
    width: 60%;
  }

  .service-cards {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 100%;
  }

  .service-details {
    width: 70%;
    height: 25%;
  }

  .service-filter {
    width: 100%;
  }

  .hero__details h1 {
    text-align: left;
    width: 95%;
    line-height: 1.5;
    font-size: 38pt !important;
  }

  #intro {
    padding: 100px 0;
  }

  .container-flex {
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }

  #newsletter_form {
    width: 90%;
  }

  .about-me-img {
    width: 95%;
    height: 456px;
    max-height: 500px;
  }

  .about-me-details {
    width: 90%;
    margin-top: 20px;
  }

  .blog-post-body {
    flex-direction: column;
  }

  .blog-post {
    width: 100%;
    margin-bottom: 10px;
  }

  .featured-container {
    padding: 100px 0;
  }

  .featured-body {
    flex-direction: row;
    height: 400px;
    position: relative;
  }

  .featured-product {
    width: 80%;
    height: 100%;
    position: absolute;
  }
}

@media screen and (max-width: 572px) {
  #hero {
    flex-direction: column;
    height: 150vh;
  }

  .hero__img {
    width: 100%;
    height: 50%;
  }

  .hero__details {
    font-size: var(--font-18);
    width: 100%;
    height: 50%;
  }

  .service-details {
    width: 100%;
    height: 20%;
  }
}

#services h1, #services p {
  text-align: center;
}

.service__cards {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  padding: 50px;
  display: flex;
}

.service__card {
  background-color: var(--bg-color);
  border-radius: 5px;
  width: 32%;
  overflow: hidden;
  box-shadow: 0 2px 10px #0000001a;
}

.service__card .header {
  max-height: 500px;
  animation: 2s linear infinite content-loading;
  overflow: hidden;
}

.service__card .body {
  text-align: left;
  padding: 20px;
}

.service__card .body p {
  text-align: left;
  margin: 10px 0;
}

.service__card .footer {
  border: 2px solid #000;
}

.service__card .footer a {
  color: var(--footer-text);
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 16pt;
  display: flex;
  color: #000 !important;
}

.service__card .learn-more {
  color: var(--info-link);
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .service__cards {
    justify-content: space-around;
  }

  .service__card {
    width: 45%;
    max-width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .service__cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 100px;
    padding: 0;
    display: flex;
  }

  .service__card {
    width: 90%;
    max-width: 600px;
  }
}

@media screen and (max-width: 572px) {
  .service__card {
    max-width: 400px;
  }
}

#blog_post_container {
  text-align: left;
  margin: 10px 0 100px;
}

#blog_post_container p {
  text-align: left;
}

#blog__main {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  display: flex;
}

#blog__main .left {
  width: 65%;
}

#blog__main .right {
  width: 30%;
}

#blog__post {
  width: 100%;
}

#blog__post .header {
  flex-direction: column;
  padding-bottom: 20px;
  display: flex;
}

#blog__post h3 {
  text-align: center;
  font-size: 14pt !important;
}

#blog__post h2 {
  margin-bottom: 0;
  font-size: 24pt !important;
}

#blog__post h1 {
  text-align: center;
  font-size: 24pt !important;
}

#blog__post p {
  text-align: left;
  box-sizing: border-box;
  padding: 10px;
}

#learn_more_container {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.learn_more {
  border: 1px solid #000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 70px;
  padding: 20px;
  font-size: 16pt;
  display: flex;
}

.blog__list li {
  padding: 10px 0;
  font-size: 16pt;
  list-style: disc;
}

.author-section {
  border-bottom: 1px solid #000;
  flex-direction: column;
  margin: 15px;
  padding-bottom: 10px;
  display: flex;
}

@media screen and (max-width: 992px) {
  #blog__main {
    flex-direction: column;
    padding-top: 50px;
  }

  #blog__main .left, #blog__main .right {
    width: 100%;
  }
}

.img-thumbnail {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  position: relative;
}

.quantity_badge {
  background-color: var(--info-link);
  font-size: var(--font-10);
  color: var(--footer-text);
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-weight: bold;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img-full {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#active_img {
  justify-content: space-between;
  align-self: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.img-absolute {
  z-index: -1;
  position: absolute;
}

@media screen and (max-width: 992px) {
  .img-full {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.checkbox-input-container {
  align-items: center;
  width: 100%;
  margin: 8px 0;
  display: flex;
  position: relative;
}

.checkbox-input {
  width: 20px;
  height: 20px;
}

.filter-label {
  font-size: var(--font-14);
  text-align: left;
  margin-left: 5px;
  font-weight: bold;
}

.range {
  flex-direction: column;
  justify-content: flex-end;
  width: 90%;
  max-width: 330px;
  height: 80px;
  display: flex;
  position: relative;
}

.price-label {
  justify-content: space-between;
  width: 90%;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 5px;
}

#province {
  font-size: 16pt;
  font-weight: normal;
  margin-bottom: 5px !important;
}

.range-input {
  width: 90%;
}

.checkout-header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 15px 0;
  display: flex;
}

.logo-header {
  text-align: center;
  font-size: var(--font-14);
}

.link-tree {
  justify-content: center;
  align-items: center;
  display: flex;
}

.link-tree-item {
  align-items: center;
  margin: 0 10px;
  display: flex;
}

.link-tree-item a {
  font-size: var(--font-12);
  margin: 0 5px;
}

.checkout-information {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding: 5px 5px 50px;
  display: flex;
  max-width: 650px !important;
}

.center-it {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.shipping-note {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 150px;
  display: flex;
}

.shipping-info {
  border: 1px solid var(--input-border);
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
  display: flex;
}

form {
  width: 100%;
}

.input-container {
  border: 1px solid var(--input-border);
  text-align: left;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 10px 0;
  display: flex;
}

.select-input {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  display: flex;
  position: relative;
}

.inputs {
  width: 100%;
  height: 100%;
  padding-left: 10px;
}

.input-row {
  display: flex;
}

.input-info {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 20px;
  padding-left: 5px;
  display: flex;
}

.input-info p {
  font-size: var(--font-10) !important;
}

.err {
  color: tomato;
  font-weight: bold;
}

.select-options {
  background-color: var(--bg-color);
  color: var(--font-14);
  border: 1px solid var(--input-border);
  border-radius: 0 0 10px 10px;
  width: 100%;
  min-height: 100px;
  position: absolute;
  top: 55px;
}

.select-options ul {
  padding: 0;
}

.select-options li {
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  padding: 5px;
  display: flex;
}

.select-label {
  font-size: var(--font-12);
  margin-left: 10px;
}

.select-icons {
  align-items: center;
  display: flex;
}

.checkout-getaway {
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 50px 0;
  display: flex;
}

.payment-checkout-getaway {
  width: 100%;
}

.payment-supported {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.checkout-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#order_summary {
  background-color: #f6f6f6;
  padding: 15px !important;
}

.checkout-summary {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.total-summary {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 25px 0;
  display: flex;
}

.total-item {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  display: flex;
}

.form-stacker {
  border: 1px solid var(--input-border);
  border-radius: 10px 10px 0 0;
  width: 100%;
  overflow: hidden;
}

.form-accordion {
  border: 1px solid;
  border: 1px solid var(--matt);
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.form-accordion-header {
  border-bottom: 1px solid var(--input-border);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
}

.form-accordion:last-child {
  border-bottom: none;
}

.form-accordion-body {
  width: 100%;
  height: 200px;
}

.form-accordion_inner {
  background-color: #d3d3d3;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 15px 50px;
  display: flex;
}

.form-accordion_inner p {
  text-align: center;
}

.container__img {
  margin-bottom: 20px;
}

.bankcard-options {
  align-items: center;
  display: flex;
}

.bankcard-img {
  border: 1px solid var(--matt);
  width: 50px;
  height: 50px;
  margin: 0 5px;
  overflow: hidden;
}

@media screen and (max-width: 999px) {
  .checkout-getaway {
    flex-direction: column-reverse;
    width: 100%;
  }

  .link-tree-item {
    margin: 0;
  }

  .center-it {
    margin-top: 60px;
  }
}

@media screen and (max-width: 572px) {
  .container__img {
    width: 40px !important;
  }
}

.btn {
  font-size: var(--font-14);
  background-color: var(--footer-bg);
  color: var(--footer-text);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 40%;
  max-width: 300px;
  height: 60px;
  margin: 0 10px 10px 0;
  padding: 15px;
  display: flex;
}

.btn-long {
  max-width: 100%;
  width: 100% !important;
}

.btn-border {
  color: var(--nav-text);
  border: 1px solid var(--nav-text);
  background-color: #0000 !important;
}

.btn-md {
  background-color: var(--footer-bg);
  color: var(--footer-text);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 60px;
  padding: 15px;
  display: flex;
  font-size: var(--font-14) !important;
}

.btn-row {
  align-items: center;
  width: 100%;
  min-height: 100px;
  display: flex;
}

.light-btn {
  color: var(--nav-text);
  background-color: #0000 !important;
}

.btn-col {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.btn-slider {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 0 15px;
  display: flex;
  box-shadow: 0 0 7px #00000021;
}

.active-crumb {
  color: var(--info-link);
  font-weight: bold;
}

.selected-crumb {
  color: var(--info-link);
}

.not-active-crumb {
  color: var(--matt-2);
}

@media screen and (max-width: 798px) {
  .btn-row {
    flex-direction: column;
    align-items: center;
  }

  .checkout-container {
    width: 100% !important;
  }

  #pay-button {
    font-weight: bold;
    font-size: var(--font-16) !important;
    width: 100% !important;
  }
}

.checkout-header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 15px 0;
  display: flex;
}

.logo-header {
  text-align: center;
  font-size: var(--font-14);
}

.link-tree {
  justify-content: center;
  align-items: center;
  display: flex;
}

.link-tree-item {
  align-items: center;
  margin: 0 10px;
  display: flex;
}

.link-tree-item a {
  font-size: var(--font-12);
  margin: 0 5px;
}

.checkout-information {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding: 5px 5px 50px;
  display: flex;
  max-width: 650px !important;
}

.center-it {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.shipping-note {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 150px;
  display: flex;
}

.shipping-info {
  border: 1px solid var(--input-border);
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
  display: flex;
}

form {
  width: 100%;
}

.input-container {
  border: 1px solid var(--input-border);
  text-align: left;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 10px 0;
  display: flex;
}

.select-input {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  display: flex;
  position: relative;
}

.inputs {
  width: 100%;
  height: 100%;
  padding-left: 10px;
}

.input-row {
  display: flex;
}

.input-info {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 20px;
  padding-left: 5px;
  display: flex;
}

.input-info p {
  font-size: var(--font-10);
}

.select-options {
  background-color: var(--bg-color);
  color: var(--font-14);
  border: 1px solid var(--input-border);
  border-radius: 0 0 10px 10px;
  width: 100%;
  min-height: 100px;
  position: absolute;
  top: 55px;
}

.select-options ul {
  padding: 0;
}

.select-options li {
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  padding: 5px;
  display: flex;
}

.select-label {
  font-size: var(--font-12);
  margin-left: 10px;
}

.select-icons {
  align-items: center;
  display: flex;
}

.checkout-getaway {
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 50px 0;
  display: flex;
}

.payment-checkout-getaway {
  width: 100%;
}

.payment-supported {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.checkout-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#order_summary {
  background-color: #f6f6f6;
  padding: 15px !important;
}

.checkout-summary {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.total-summary {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 25px 0;
  display: flex;
}

.total-item {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  display: flex;
}

.form-stacker {
  border: 1px solid var(--input-border);
  border-radius: 10px 10px 0 0;
  width: 100%;
  overflow: hidden;
}

.form-accordion {
  border: 1px solid;
  border: 1px solid var(--matt);
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.form-accordion-header {
  border-bottom: 1px solid var(--input-border);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
}

.form-accordion:last-child {
  border-bottom: none;
}

.form-accordion-body {
  width: 100%;
  height: 200px;
}

.form-accordion_inner {
  background-color: #d3d3d3;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 15px 50px;
  display: flex;
}

.form-accordion_inner p {
  text-align: center;
}

.container__img {
  margin-bottom: 20px;
}

.bankcard-options {
  align-items: center;
  display: flex;
}

.bankcard-img {
  border: 1px solid var(--matt);
  width: 50px;
  height: 50px;
  margin: 0 5px;
  overflow: hidden;
}

@media screen and (max-width: 999px) {
  .checkout-getaway {
    flex-direction: column-reverse;
    width: 100%;
  }

  .payment-supported {
    margin-top: 50px;
  }

  .link-tree-item {
    margin: 0;
  }

  .center-it {
    margin-top: 60px;
  }
}

@media screen and (max-width: 572px) {
  .container__img {
    width: 40px !important;
  }
}

.container {
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.container-col {
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left-container {
  box-sizing: border-box;
  width: 60%;
  padding: 5px;
}

.right-container {
  box-sizing: border-box;
  width: 35%;
  padding: 15px;
}

@media screen and (max-width: 999px) {
  .product-wrapper {
    padding-top: 0 !important;
  }

  .container {
    flex-direction: column;
  }

  .left-container {
    width: 100%;
    padding: 0;
  }

  .right-container {
    width: 100%;
  }

  .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .by3-1 {
    justify-content: space-between;
    width: 100%;
  }
}

#modal_wrapper {
  z-index: 9999999;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110vh;
  display: flex;
  position: fixed;
  left: 0;
  top: 0 !important;
}

#modal {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

#modal_content {
  background-color: var(--nav-bg);
  width: 90%;
  max-width: 800px;
  min-height: 200px;
  max-height: 80vh;
  position: absolute;
  top: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-filter {
  background-color: #00000075;
  width: 100%;
  height: 100%;
}

.policy__container {
  margin: 50px 0;
  padding: 100px;
}

@media screen and (max-width: 992px) {
  .policy__container {
    padding: 15px !important;
  }
}

.no__item {
  padding: 100px;
}

.cart-notification {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  display: flex;
}

.close-modal {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
}

.cart-img-ad {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.cart-item-row {
  align-items: center;
  width: 100%;
  display: flex;
}

.cart-ad-items {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  height: 300px !important;
}

.cart-ad-item {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.img-modal-item {
  border: 1px solid;
  width: 40%;
  margin-right: 10px;
}

.cart-short-id {
  width: 55%;
}

#pd__ {
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  display: flex;
}

#pd__ .left__item {
  box-sizing: border-box;
  width: 60%;
  padding: 5px;
}

#pd__ .right__item {
  box-sizing: border-box;
  width: 35%;
  padding: 15px;
}

#pd__ .bullet-point {
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0;
  list-style: disc;
}

.pd__active-product {
  box-sizing: border-box;
  pointer-events: visiblePainted;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  display: flex;
  position: relative;
}

#pd__ .review-stamp, #pd__ .review-icons {
  align-items: center;
  display: flex;
}

#pd__ .star-icon {
  background-color: var(--bg-color);
}

#pd__ .icon-sm {
  font-size: var(--font-14);
}

#pd__ .product-id {
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
  display: flex;
}

#pd__ .product-id h2 {
  text-transform: uppercase;
  font-size: 24pt;
}

#pd__ .product-id p {
  font-size: 16pt;
}

#pd__ .product-description {
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;
  display: flex;
}

#pd__ .product-description h3 {
  text-transform: uppercase;
  font-size: 18pt !important;
}

#pd__ .flavor-details {
  margin: 30px 0;
}

#pd__ .flavor-btn {
  border: 1px solid var(--matt-2);
  border-radius: 10px;
  margin: 2px;
  box-shadow: 0 0 2px #0000001a;
}

.pd_full__accordion {
  border: none !important;
  width: 100% !important;
  max-width: 100% !important;
}

.pd_b__bottom {
  border-bottom: 1px solid #f5f5f5 !important;
}

@media screen and (max-width: 992px) {
  #pd__ {
    flex-direction: column;
  }

  #pd__ .left__item {
    width: 100%;
    padding: 0;
  }

  #pd__ .right__item {
    width: 100%;
  }
}

#business_opportunity {
  background-color: #fff !important;
}

.main__container {
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 50px auto 0;
  padding: 20px;
  display: flex;
}

.business-business__hero {
  color: var(--white);
  text-align: center;
  padding: 100px 0;
}

.title {
  font-size: var(--font-36);
}

.description {
  font-size: var(--font-18);
  text-align: left;
  margin-bottom: 40px;
}

.cta-button {
  background-color: var(--color-darkbrown);
  color: var(--white);
  font-size: var(--font-16);
  padding: 15px 30px;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.cta-button:hover {
  background-color: var(--color-lightbrown);
}

.form-section {
  background-color: var(--matt);
  text-align: center;
  padding: 60px 0;
}

.section-title {
  font-size: var(--font-28);
}

.section-description {
  font-size: var(--font-14);
  margin-bottom: 30px;
}

.form-iframe {
  border: none;
  width: 100%;
  height: 500px;
}

body {
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}

.business__container {
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  display: flex;
}

.business__card {
  background-color: #fff;
  border-radius: 8px;
  max-width: 260px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px #0000001a;
}

.business__card h2 {
  text-align: center;
}

.business__card p {
  color: #333;
  text-align: center;
  line-height: 1.6;
}

.checkout-header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 15px 0;
  display: flex;
}

.logo-header {
  text-align: center;
  font-size: var(--font-14);
}

.link-tree {
  justify-content: center;
  align-items: center;
  display: flex;
}

.link-tree-item {
  align-items: center;
  margin: 0 10px;
  display: flex;
}

.link-tree-item a {
  font-size: var(--font-12);
  margin: 0 5px;
}

.checkout-information {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding: 5px 5px 50px;
  display: flex;
  max-width: 650px !important;
}

.center-it {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.shipping-note {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 150px;
  display: flex;
}

.shipping-info {
  border: 1px solid var(--input-border);
  text-align: left;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
  display: flex;
}

form {
  width: 100%;
}

.pad-10 {
  padding: 10px;
}

.input-container {
  border: 1px solid var(--input-border);
  text-align: left;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 10px 0;
  display: flex;
  overflow: hidden;
}

.select-input {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  display: flex;
  position: relative;
}

.inputs {
  width: 100%;
  height: 100%;
  padding-left: 10px;
}

.input-row {
  display: flex;
}

.input-info {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 20px;
  padding-left: 5px;
  display: flex;
}

.input-info p {
  font-size: var(--font-10);
}

.select-options {
  background-color: var(--bg-color);
  color: var(--font-14);
  border: 1px solid var(--input-border);
  border-radius: 0 0 10px 10px;
  width: 100%;
  min-height: 100px;
  position: absolute;
  top: 55px;
}

.select-options ul {
  padding: 0;
}

.select-options li {
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  padding: 5px;
  display: flex;
}

.select-label {
  font-size: var(--font-12);
  margin-left: 10px;
}

.select-icons {
  align-items: center;
  display: flex;
}

.checkout-getaway {
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 50px 0;
  display: flex;
}

.payment-checkout-getaway {
  width: 100%;
}

.payment-supported {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.checkout-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#order_summary {
  background-color: #f6f6f6;
  padding: 15px !important;
}

.checkout-summary {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.total-summary {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 25px 0;
  display: flex;
}

#total__item {
  border-top: 1px solid #262626;
  padding-top: 10px;
}

.total-item {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  margin: 5px 0;
  display: flex;
}

.form-stacker {
  border: 1px solid var(--input-border);
  border-radius: 10px 10px 0 0;
  width: 100%;
  overflow: hidden;
}

.form-accordion {
  border: 1px solid;
  border: 1px solid var(--matt);
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.form-accordion-header {
  border-bottom: 1px solid var(--input-border);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
}

.form-accordion:last-child {
  border-bottom: none;
}

.form-accordion-body {
  width: 100%;
  height: 200px;
}

.form-accordion_inner {
  background-color: #d3d3d3;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 15px 50px;
  display: flex;
}

.form-accordion_inner p {
  text-align: center;
}

.container__img {
  margin-bottom: 20px;
}

.bankcard-options {
  align-items: center;
  display: flex;
  border: none !important;
}

.bankcard-img {
  border: none;
  width: 50px;
  height: 50px;
  margin: 0 5px;
  overflow: hidden;
}

@media screen and (max-width: 999px) {
  .checkout-getaway {
    flex-direction: column-reverse;
    width: 100%;
  }

  .checkout-header {
    padding-top: 50px;
  }

  .link-tree-item {
    margin: 0;
  }

  .center-it {
    margin-top: 60px;
  }

  #order_summary {
    position: fixed;
    top: 60px;
    left: 0;
  }

  .translate-Y {
    transform: translateY(-200%);
  }
}

@media screen and (max-width: 572px) {
  .container__img {
    width: 40px !important;
  }
}

.cart-mini {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 50px;
  left: 0;
}

.cart-min-filter {
  background-color: #0000003a;
  width: 100%;
  height: 100%;
}

.cart-min-container {
  background-color: var(--bg-color);
  width: 85%;
  max-width: 600px;
  height: 100%;
  position: fixed;
  top: 50px;
  right: 0;
}

.cart-mini-header {
  border-bottom: 1px solid #d3d3d3;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 0 10px;
  display: flex;
}

#cart_no {
  z-index: 10;
  color: var(--footer-bg);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  top: -3px;
  left: 11px;
  font-size: var(--font-10) !important;
}

#cart_ {
  font-size: var(--font-10) !important;
}

.close-cart {
  justify-content: center;
  width: 20%;
  display: flex;
}

.close-cart-icon {
  font-size: var(--font-28);
}

.cart-items {
  background-color: #fcfcfc;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 45vh;
  padding: 10px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.cart-item {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-main {
  text-align: center;
  width: 50%;
}

.payment__title {
  width: 100%;
  margin: 50px 0 10px;
}

.payment__title p:nth-child(2) {
  font-size: 18px;
}

.no__item {
  padding: 100px;
}

.cart-notification {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  display: flex;
}

.close-modal {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
}

.cart-img-ad {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.cart-item-row {
  align-items: center;
  width: 100%;
  display: flex;
}

.cart-ad-items {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  height: 300px !important;
}

.cart-ad-item {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.img-modal-item {
  border: 1px solid;
  width: 40%;
  margin-right: 10px;
}

.cart-short-id {
  width: 55%;
}

.cart-title {
  justify-content: center;
  align-items: center;
  height: 100px;
  display: flex;
}

.table-row {
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  display: flex;
}

.cart-table {
  width: 70%;
}

.cart-table-header {
  visibility: visible;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.cart-table-header p {
  text-transform: capitalize;
}

.cart-table-body {
  border-bottom: 1px solid var(--footer-bg);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.cart-table-footer {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 10px;
  display: flex;
}

.cart-table-cell-main {
  align-items: center;
  width: 60%;
  display: flex;
}

.cart-table-cell {
  text-align: right;
  justify-content: center;
  align-items: center;
  width: 20%;
  display: flex;
}

.counter-container {
  text-align: center;
  border-bottom: 1px solid;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 60px;
  padding: 5px;
  display: flex;
}

.cart-items-header {
  display: none;
}

.cart-summary-item {
  border-bottom: 1px solid var(--matt);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 15px;
  display: flex;
}

.cart-summary-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 489px) {
  .cart-table-cell-main {
    width: 100%;
  }

  .cart-table-cell {
    width: 40%;
  }

  .cart-items-header {
    z-index: 99;
    background-color: var(--footer-bg);
    color: var(--footer-text);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    display: flex;
    position: fixed;
    top: 0;
  }

  .right-checkout-details {
    padding: 0 !important;
  }

  .show-summary-btn {
    align-items: center;
    display: flex;
    color: var(--footer-text) !important;
  }

  .cart-summary-item {
    text-align: left;
    border-bottom: 1px solid var(--matt);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
  }

  .cart-summary-item:last-child {
    border-bottom: none;
  }

  .mobile-show {
    display: none;
  }

  .cart-table-header {
    visibility: hidden;
  }
}

@media screen and (max-width: 572px) {
  .no__item {
    text-align: center;
    margin: 50px 0;
    padding: 20px;
  }
}

@media screen and (max-width: 999px) {
  .cart-table {
    width: 100%;
  }

  .cart-items-header {
    z-index: 99;
    background-color: var(--footer-bg);
    color: var(--footer-text);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    display: flex;
    position: fixed;
    top: 0;
  }

  .right-checkout-details {
    padding: 0 !important;
  }

  .show-summary-btn {
    align-items: center;
    display: flex;
    color: var(--footer-text) !important;
  }

  .cart-summary-item {
    text-align: left;
    border-bottom: 1px solid var(--matt);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
  }

  .cart-summary-item:last-child {
    border-bottom: none;
  }

  .mobile-show {
    display: none;
  }

  .cart-table-header {
    visibility: hidden;
  }
}

.quantity__container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.quantity-counter {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 70px;
  margin: 0;
  display: flex;
}

.quantity__container .add-to-cart {
  background-color: var(--footer-bg);
  border-radius: 0 2px 2px 0;
  justify-content: center;
  align-items: center;
  width: 45%;
  margin: 0;
  padding: 0;
  display: flex;
  height: 75px !important;
}

.add-bag-btn {
  color: var(--footer-text);
  font-size: var(--font-14);
  border-radius: 5px !important;
}

.err-window {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  display: flex;
}

.err-window div {
  align-items: center;
  padding: 40px 0;
  display: flex;
}

.process-payment {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 35px;
  display: flex;
}

.process-payment p {
  position: relative;
  top: -30px;
}

#process_payment_img {
  width: 250px;
  height: 250px;
}

.err-icon {
  color: tomato;
  font-size: 24pt !important;
}

#err_el {
  text-align: center;
  margin: 20px 0;
  font-size: 16pt !important;
}

footer {
  background-color: var(--footer-bg);
  color: var(--footer-text);
  flex-direction: column;
  place-content: center;
  width: 100%;
  min-height: 250px;
  padding: 20px;
  display: flex;
}

#footer {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

#footer p {
  font-size: 16pt !important;
}

.footer-col {
  text-align: left;
  width: 30%;
  height: 250px;
  margin: 10px;
  padding: 10px;
}

.footer-col p {
  text-align: left !important;
}

#footer-policy {
  text-align: center;
  color: var(--footer-text);
  justify-content: space-around;
  align-items: center;
  display: flex;
}

#footer-policy p {
  font-size: 14pt !important;
}

.policy-link {
  color: var(--footer-text);
  margin: 0 8px;
}

.footer-item {
  padding: 0;
}

.footer-item li {
  margin: 10px 0;
}

.footer-link {
  font-size: var(--font-12);
  color: var(--footer-text);
}

@media screen and (max-width: 789px) {
  #footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-col {
    width: 100%;
    max-width: 700px;
    height: auto;
  }

  .footer-col p {
    text-align: left;
  }

  .policies {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: flex;
  }

  .policies a {
    border-bottom: 1px solid var(--matt);
    text-align: left;
    width: 100%;
    padding: 10px;
  }

  .footer-link {
    font-size: var(--font-14);
  }

  #footer-policy {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

* {
  box-sizing: border-box !important;
}

body {
  margin: 0;
  background-color: #fff !important;
  overflow-x: hidden !important;
}

button {
  color: #000;
  cursor: pointer;
  background-color: #0000;
  border: none;
}

a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

li {
  box-sizing: border-box;
  list-style: none;
}

label {
  cursor: pointer;
}

input {
  background-color: #0000;
  border: none;
}

.nav-list {
  height: 100%;
  margin: 0;
}

#app {
  flex-direction: column;
  width: 100%;
  display: flex;
}

#editor {
  height: 80vh;
  overflow-y: scroll;
}

.ql-editor {
  height: 65vh;
}

.translate-up {
  transform: translateY(-200%);
}

.info-link {
  color: var(--info-link) !important;
}

.font-10 {
  font-size: var(--font-10) !important;
}

.content-is-loading {
  animation: 2s linear infinite loading-content !important;
}

#call_to_action {
  z-index: 2147483647;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 16pt;
  position: fixed;
  bottom: 50px;
  right: 50px;
  box-shadow: 0 0 10px #0000008f;
  background-color: #6bb3fb5c !important;
}

#call_to_action:hover {
  background-color: #6bb3fbb8 !important;
}

@keyframes loading-content {
  0% {
    background-color: #fff;
  }

  50% {
    background-color: #fff;
  }

  100% {
    background-color: #fff;
  }
}

#loading_res {
  z-index: 2147483647;
  width: 100%;
  height: 100vh;
  animation: 3s linear infinite loader;
  position: fixed;
}

@keyframes loader {
  0% {
    background-color: #0003;
  }

  50% {
    background-color: #00000080;
  }

  100% {
    background-color: #0003;
  }
}

.is-desktop {
  display: flex !important;
}

.is-mobile {
  display: none !important;
}

.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.icon-md {
  font-size: 24pt;
}

.icon-sm {
  font-weight: bold;
  font-size: 24pt !important;
}

h1 {
  font-size: 36pt;
  font-weight: bold;
}

h2 {
  font-family: Open Sans, sans-serif;
  font-size: 28pt;
}

p {
  font-family: Open Sans, sans-serif;
  font-size: 18pt;
  font-weight: 300;
  line-height: 1.5;
}

svg {
  cursor: pointer !important;
}

button, a, input {
  font-family: Open Sans, sans-serif;
}

.take__quiz {
  z-index: 9999999;
  color: #fff;
  background-color: #b4c195;
  border-radius: 10px;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px;
  display: flex;
  position: fixed;
  bottom: 50px;
  right: 100px;
}

.take__quiz span {
  font-size: 16pt;
}

.take__quiz-icon {
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  font-size: 16pt;
}

.rotate-open {
  transform: rotate(180deg);
}

@media screen and (max-width: 992px) {
  .is-desktop {
    display: none !important;
  }

  .is-mobile {
    display: flex !important;
  }

  .take__quiz {
    height: 80px;
    right: 40px;
  }
}

@media screen and (max-width: 572px) {
  .translate-left {
    transform: translateX(0%) !important;
  }

  #call_to_action {
    border-radius: 10px 10px 0 0;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .is-desktop {
    display: none !important;
  }

  .is-mobile {
    display: flex !important;
  }

  .take__quiz {
    border-radius: 0;
    width: 100%;
    height: 80px;
    bottom: 0;
    right: 0;
  }

  h1 {
    font-size: 30pt !important;
  }

  h2 {
    font-size: 24pt !important;
  }

  p {
    line-height: 1.5;
    font-size: 18pt !important;
  }
}

:root {
  --black: #000;
  --white: #fff;
  --nav-bg: #fff;
  --nav-bg-light: #ebebeb;
  --nav-text: #262626;
  --bg-color: #fff;
  --hover-color: whitesmoke;
  --footer-bg: #262626;
  --footer-text: #fff;
  --info-link: dodgerblue;
  --matt: #f5f5f58d;
  --matt-2: #b1b1b1d1;
  --input-border: lightgray;
  --color-darkbrown: #de9756;
  --color-lightbrown: #bc986a;
  --color-paleyellow: #fbeec1;
  --color-smoothgreen: #b4c195;
  --color-darkolive: #697739;
  --font-8: 6pt;
  --font-10: 10pt;
  --font-12: 12pt;
  --font-14: 14pt;
  --font-16: 16pt;
  --font-18: 18pt;
  --font-24: 24pt;
  --font-28: 28pt;
  --font-36: 36pt;
  --font-42: 42pt;
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

* {
  font-family: Open Sans, sans-serif;
  transition: all .4s ease-in-out;
  box-sizing: border-box !important;
}

.b {
  border: 1px solid red;
}

#splash_screen h1 {
  font-size: 56pt;
  animation: 2s ease-in-out infinite loader-splash;
}

@keyframes loader-splash {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.8);
  }

  to {
    transform: scale(1);
  }
}

.hide-it {
  display: none !important;
}

.posts__container {
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1080px;
  padding: 15px;
  display: flex;
}

.tutorial__card {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 32%;
  height: 500px;
  display: flex;
  overflow: hidden;
}

.tutorial__card-header {
  border-bottom: 1px solid light gray;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.tutorial__card-header img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tutorial__card-footer {
  flex-direction: column;
  height: 50%;
  padding: 15px;
  display: flex;
}

.tutorial__card-title {
  margin: 5px;
}

.tutorial__card-description {
  margin-top: 0;
  overflow: hidden;
  text-align: left !important;
}

.tutorial__card-type {
  color: #fff;
  background-color: #caa269;
  border-radius: 2px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .tutorial__card {
    max-width: 45%;
  }

  .post__container {
    width: 100%;
  }

  .post__header {
    padding: 10px;
  }
}

@media screen and (max-width: 572px) {
  .tutorial__card {
    max-width: 90%;
  }
}

#quiz__modal {
  z-index: 2147483647;
  justify-content: center;
  width: 100%;
  height: 110vh;
  display: flex;
  position: fixed;
  left: 0;
  top: 0 !important;
}

#quiz__modal-content {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 550px;
  height: 70%;
  margin-top: 50px;
  padding: 15px;
  display: flex;
  position: absolute;
}

.quiz__modal-header {
  text-align: right;
  width: 100%;
  padding: 10px;
}

.close__quiz-close-btn {
  font-size: 24pt;
}

.quiz__modal-filter {
  background-color: #00000075;
  width: 100%;
  height: 100%;
}

.quiz__modal-cards {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  width: 90%;
  max-width: 550px;
  height: 55%;
  margin-top: 0;
  padding: 15px;
  display: flex;
  overflow-y: auto;
}

.quiz__modal-card {
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  padding: 10px 0;
  display: flex;
}

.quiz__modal-card:hover {
  border: 1px solid #45828b;
}

.quiz__modal-card p {
  background-color: #f5f5f5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0;
  display: flex;
}

.quiz__modal-card h3 {
  margin: 10px;
  font-size: 18pt;
}

@media screen and (max-width: 572px) {
  #quiz__modal-content {
    border-radius: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .quiz__modal-cards {
    height: 44%;
  }
}

#write_block {
  width: 100%;
  max-width: 900px;
  margin: 10px 0 100px;
  padding: 10px;
}

#write_block .tooltit__header {
  z-index: 9999999;
  background-color: #0000004a;
  justify-content: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#write_block .tooltit__header #writing_block-information {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 40%;
  max-width: 572px;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 50px;
  padding: 20px;
  display: flex;
  box-shadow: 0 0 10px #0000004a;
}

#write_block .tooltit__header #writing_block-information .start__writing {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
}

#write_block .tooltit__header #writing_block-information .start__writing .close_toolkit_header-close {
  font-size: 1.8rem;
}

#write_block .tooltit__header #writing_block-information .start__writing .close_toolkit_header-close:hover {
  background-color: #f5f5f5;
  border-radius: 50px;
  transform: scale(.9);
}

#write_block .tooltit__header #writing_block-information .start__writing .start__writing-btn {
  background-color: #0000;
  border: none;
}

#write_block .tooltit__header #writing_block-information .start__writing .start__writing-btn .start__writing-icon {
  font-size: 1.6rem !important;
}

#write_block .tooltit__header #writing_block-information .start__writing .start__writing-btn .start__writing-icon:hover {
  background-color: #f5f5f5;
  border-radius: 50px;
  transform: scale(.9);
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form {
  padding-top: 0;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .editor__btn-save {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  margin: 20px 0;
  font-size: 1.3rem;
  display: flex;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .editor__btn-save span {
  margin: 0 10px;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container {
  flex-direction: column !important;
  justify-content: flex-start !important;
  margin-bottom: 15px !important;
  display: flex !important;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container label {
  position: static;
  font-size: 1.6rem !important;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #writing_inputs-img {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  display: flex;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #title {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  height: 45px;
  margin: 20px 10px;
  padding: 0 15px;
  font-size: 1.4rem;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #title:focus {
  border: none;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #description {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  height: 45px;
  margin: 20px 10px;
  padding: 0 15px;
  font-size: 1rem;
}

#write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #img_cover {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 572px) {
  #write_block .tooltit__header #writing_block-information {
    justify-content: center;
    gap: 5px;
    max-width: 572px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px 0;
    width: 80% !important;
  }

  #write_block .tooltit__header #writing_block-information #writing_block_info_form {
    width: 80%;
    padding-top: 0;
  }

  #write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container {
    flex-direction: column !important;
    justify-content: flex-start !important;
    margin-bottom: 15px !important;
    display: flex !important;
  }

  #write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container label {
    font-size: 1.2rem !important;
  }

  #write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #writing_inputs-img {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  #write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #title {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    height: 45px;
    margin: 10px 10px 0;
    padding: 5px;
    font-size: 1.4rem;
    font-weight: bold;
  }

  #write_block .tooltit__header #writing_block-information #writing_block_info_form .input__container #title:focus {
    border: none;
  }

  #write_block .tooltit__header #writing_block-information #writing_block_info_form button {
    margin: 0;
  }
}

#write_block .header {
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

#write_block .ql-tooltip {
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

#write_block iframe {
  width: 100%;
  height: 400px;
  overflow: hidden !important;
}

#write_block iframe div {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#write_block .ql-toolbar {
  z-index: 99999;
  border-radius: 5px 5px 0 0;
  position: sticky;
  top: 10px;
  background-color: #fff !important;
}

#write_block #editor {
  min-height: 40vh;
  overflow-y: visible;
}

#write_block #editor p {
  text-align: left;
}

#write_block #editor pre {
  max-width: 80%;
  padding: 20px;
  font-size: 1rem;
}

#write_block #editor img {
  object-fit: contain;
  max-height: 400px !important;
}

#write_block #editor_footer {
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  display: flex;
}

#write_block #editor_footer .editor__btn-save {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  margin: 20px 0;
  font-size: 1.3rem;
  display: flex;
}

#write_block #editor_footer .editor__btn-save span {
  margin: 0 10px;
}

#write_block #editor_footer .editor__btn-publish {
  background-color: #bde5bd;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  margin: 20px 0;
  font-size: 1.3rem;
  display: flex;
}

#write_block #editor_footer .editor__btn-publish span {
  margin: 0 10px;
}

.m_editor__footer {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
}

.m_editor__footer .editor__btn-save {
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.m_editor__footer .editor__btn-publish {
  color: #000;
  background-color: #bbff80;
  border-radius: 5px;
  padding: 10px;
}

#landing_page {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  display: flex;
  background-color: #fff !important;
}

#landing_page .header {
  border: 1px solid;
  border-radius: 20px;
  width: 100%;
  max-width: 992px;
  height: 500px;
  margin: 20px 0;
  overflow: hidden;
}

#landing_page .header .fg {
  width: 100%;
  height: 500px;
  overflow: hidden;
}

#landing_page .header .fg .img-cover {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

#landing_page .body {
  max-width: 992px;
  padding: 10px;
}

@media screen and (max-width: 992px) {
  #landing_page {
    margin-top: 10px;
  }
}

#login_screen {
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
  padding-top: 30px;
  display: flex;
}

#login_screen #login_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

#login_screen .login-form {
  background-color: #fff;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  box-shadow: 0 2px 4px #0000001a;
}

#login_screen .form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

#login_screen .form-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  display: block;
}

#login_screen .form-button {
  color: #fff;
  cursor: pointer;
  background-color: #f80;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

#login_screen .form-text {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

#login_screen .form-text a {
  color: #f80;
  text-decoration: none;
}

#login_screen #login_banner {
  display: none;
}

@media (min-width: 572px) {
  #login_screen #login_banner {
    width: 50%;
    height: 100vh;
    display: flex;
  }

  #login_screen #login_form {
    width: 50%;
  }

  #login_screen .form-input {
    height: 55px;
  }
}

@media (min-width: 992px) {
  #login_screen {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  #login_screen #login_form {
    width: 50%;
    height: 100vh;
    padding-top: 50px;
  }

  #login_screen .login-form {
    width: 100%;
    max-width: 560px;
    padding: 40px;
  }

  #login_screen .form-title {
    font-size: 32px;
  }

  #login_screen .form-input {
    height: 60px;
    font-size: 18px;
  }

  #login_screen .form-button {
    height: 55px;
    font-size: 18px;
  }

  #login_screen .form-text {
    font-size: 12px !important;
  }
}

#registration_form {
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

#registration_form .signup-form {
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  padding: 40px;
  box-shadow: 0 2px 4px #0000001a;
}

#registration_form .form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

#registration_form .form-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  display: block;
}

#registration_form .form-button {
  color: #fff;
  cursor: pointer;
  background-color: #f80;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

#registration_form .form-text {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

#registration_form .form-text a {
  color: #f80;
  text-decoration: none;
}

@media (min-width: 572px) {
  #registration_form {
    width: 50%;
  }

  #registration_form .form-input {
    height: 55px;
  }
}

@media (min-width: 992px) {
  #registration_form {
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100vh;
    padding-top: 200px;
    display: flex;
  }

  #registration_form .signup-form {
    max-width: 500px;
    padding: 40px;
  }

  #registration_form .form-title {
    font-size: 32px;
  }

  #registration_form .form-input {
    height: 60px;
    font-size: 18px;
  }

  #registration_form .form-button {
    height: 55px;
    font-size: 18px;
  }

  #registration_form .form-text {
    font-size: 16px;
  }
}

#err_inputs {
  color: tomato;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 14px;
  display: flex;
  text-align: center !important;
}

#blog__main {
  margin-top: 100px;
  padding: 10px;
}

#blog__main a {
  color: #1e90ff !important;
}

#blog__main img {
  height: 400px;
  object-fit: contain !important;
  width: 100% !important;
}

#blog__main li {
  list-style: inherit !important;
}

#blog__main iframe {
  align-items: center;
  height: 500px;
  margin: 20px;
  display: flex;
  object-fit: contain !important;
  width: 50% !important;
}

@media screen and (max-width: 992px) {
  #blog__main iframe {
    align-items: center;
    height: 500px;
    margin: 5px;
    display: flex;
    object-fit: contain !important;
    width: 100% !important;
  }
}

/*# sourceMappingURL=index.7d50bd3b.css.map */
