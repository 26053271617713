.btn {
  width: 40%;
  max-width: 300px;
  height: 60px;

  font-size: var(--font-14);

  padding: 15px;

  margin: 0 10px 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--footer-bg);
  color: var(--footer-text);

  border-radius: 10px;
}

.btn-long {
  width: 100% !important;
  max-width: 100%;
}

.btn-border {
  color: var(--nav-text);
  border: 1px solid var(--nav-text);
  background-color: transparent !important;
}

.btn-md {
  /* width: 50%; */
  max-width: 300px;
  height: 60px;

  padding: 15px;

  font-size: var(--font-14) !important;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--footer-bg);
  color: var(--footer-text);

  border-radius: 10px;
}

.btn-row {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  min-height: 100px;
}

.light-btn {
  background-color: transparent !important;
  color: var(--nav-text);
}

.btn-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-slider {
  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);

  margin: 0 15px;

  border-radius: 50%;
}

/* Breadcrums */
.active-crumb {
  color: var(--info-link);
  font-weight: bold;
}
.selected-crumb {
  color: var(--info-link);
}
.not-active-crumb {
  color: var(--matt-2);
}

@media screen and (max-width: 798px) {
  .btn-row {
    flex-direction: column;
    align-items: center;
  }

  .checkout-container {
    width: 100% !important;
  }

  #pay-button {
    width: 100% !important;

    font-size: var(--font-16) !important;
    font-weight: bold;
  }
}
