.price__items {
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.price__items input {
  width: 100%;
}
