.posts__container {
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 20px;
  padding: 15px;
  /* margin: 150px 0; */
}

.tutorial__card {
  width: 100%;
  max-width: 32%;
  height: 500px;

  background-color: white;

  border-radius: 5px;
  border: 1px solid lightgray;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  overflow: hidden;
}
.tutorial__card-header {
  width: 100%;
  height: 50%;
  border-bottom: 1px solid light gray;

  overflow: hidden;
}

.tutorial__card-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tutorial__card-footer {
  height: 50%;
  padding: 15px;

  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}
.tutorial__card-title {
  margin: 5px;
}
.tutorial__card-description {
  /* height: 45%; */
  overflow: hidden;
  margin-top: 0;
  text-align: left !important;
  /* padding: 15px; */
}
.tutorial__card-type {
  overflow: hidden;
  background-color: rgb(202, 162, 105);

  display: flex;
  align-items: center;
  color: white;
  width: fit-content;
  padding: 10px;

  border-radius: 2px;

  /* padding: 0 15px;
  margin: 15px; */
}

@media screen and (max-width: 992px) {
  .tutorial__card {
    max-width: 45%;
  }
  .post__container {
    width: 100%;
  }

  .post__header {
    padding: 10px;
  }
}
@media screen and (max-width: 572px) {
  .tutorial__card {
    max-width: 90%;
  }
}
