#business_opportunity {
  background-color: white !important;
}

.main__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;
}

.business-business__hero {
  /* background-image: url("poster-image.jpg");
        background-size: cover;
        background-position: center; */
  color: var(--white);
  padding: 100px 0;
  text-align: center;
}

.title {
  font-size: var(--font-36);
}

.description {
  font-size: var(--font-18);
  margin-bottom: 40px;
  text-align: left;
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: var(--color-darkbrown);
  color: var(--white);
  text-decoration: none;
  font-size: var(--font-16);
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--color-lightbrown);
}

.form-section {
  background-color: var(--matt);
  padding: 60px 0;
  text-align: center;
}

.section-title {
  font-size: var(--font-28);
}

.section-description {
  font-size: var(--font-14);
  margin-bottom: 30px;
}

.form-iframe {
  width: 100%;
  height: 500px;
  border: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.business__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.business__card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 10px;

  max-width: 260px;
}

.business__card h2 {
  /* color: #007bff; */
  text-align: center;
}

.business__card p {
  color: #333;
  line-height: 1.6;
  text-align: center;
}
