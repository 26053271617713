/* @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:wght@700&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Lobster+Two:wght@700&family=Open+Sans:wght@300;600&display=swap");

*/

@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:wght@700&family=Open+Sans:wght@300&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@600&display=swap"); */
@import url("./css/index.css");

:root {
  --black: #000000;
  --white: #fff;

  --nav-bg: #ffffff;
  --nav-bg-light: #ebebeb;
  --nav-text: #262626;
  --bg-color: #fff;
  --hover-color: whitesmoke;
  --footer-bg: #262626;
  --footer-text: #fff;
  --info-link: dodgerblue;
  --matt: rgba(245, 245, 245, 0.552);
  --matt-2: rgba(177, 177, 177, 0.818);
  --input-border: lightgray;

  --color-darkbrown: #de9756;
  --color-lightbrown: #bc986a;
  --color-paleyellow: #fbeec1;
  --color-smoothgreen: #b4c195;
  --color-darkolive: #697739;

  --font-8: 6pt;
  --font-10: 10pt;
  --font-12: 12pt;
  --font-14: 14pt;
  --font-16: 16pt;
  --font-18: 18pt;
  --font-24: 24pt;
  --font-28: 28pt;
  --font-36: 36pt;
  --font-42: 42pt;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

* {
  /* font-family: "Comfortaa", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box !important;

  transition: 0.4s ease-in-out;
}

.b {
  border: 1px solid red;
}

#splash_screen h1 {
  font-size: 56pt;

  /* font-family: fantasy !important; */

  animation: loader-splash 2s ease-in-out infinite;
}

@keyframes loader-splash {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

.hide-it {
  display: none !important;
}
