#login_screen {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;

  padding-top: 30px;

  #login_form {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-form {
    width: 100%;
    max-width: 300px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .form-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .form-input {
    display: block;
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .form-button {
    display: block;
    width: 100%;
    height: 45px;
    background-color: #f80;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .form-text {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
  }

  .form-text a {
    color: #f80;
    text-decoration: none;
  }

  #login_banner {
    display: none;

    @media (min-width: 572px) {
      display: flex;
      width: 50%;
      height: 100vh;
      // border: 1px solid;
    }
  }

  @media (min-width: 572px) {
    #login_form {
      width: 50%;
    }
    .form-input {
      height: 55px;
    }
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #login_form {
      width: 50%;
      height: 100vh;
      padding-top: 50px;
    }

    .login-form {
      width: 100%;
      max-width: 560px;
      padding: 40px;

      // border: 1px solid red;
    }

    .form-title {
      font-size: 32px;
    }

    .form-input {
      height: 60px;
      font-size: 18px;
    }

    .form-button {
      height: 55px;
      font-size: 18px;
    }

    .form-text {
      font-size: 12px !important;
    }
  }

  @media (min-width: 1300px) {
  }
}

#registration_form {
  width: 100%;
  padding-top: 20px;

  display: flex;
  justify-content: center;

  overflow: hidden;
  overflow-y: auto;

  .signup-form {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .form-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .form-input {
    display: block;
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .form-button {
    display: block;
    width: 100%;
    height: 45px;
    background-color: #f80;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .form-text {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
  }

  .form-text a {
    color: #f80;
    text-decoration: none;
  }

  @media (min-width: 572px) {
    width: 50%;
    .form-input {
      height: 55px;
    }
  }

  @media (min-width: 992px) {
    padding-top: 200px;
    height: 100vh;
    // styles.scss
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    // border: 1px solid;

    .signup-form {
      max-width: 500px;
      padding: 40px;
    }

    .form-title {
      font-size: 32px;
    }

    .form-input {
      height: 60px;
      font-size: 18px;
    }

    .form-button {
      height: 55px;
      font-size: 18px;
    }

    .form-text {
      font-size: 16px;
    }
  }
}

#err_inputs {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  text-align: center !important;
  color: tomato;

  font-size: 14px;
  // margin: 5px 0;
}
