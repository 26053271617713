.cart-mini {
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 50px;
  left: 0;

  /* margin-top: 50px; */

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart-min-filter {
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.226);
}
.cart-min-container {
  position: fixed;
  top: 50px;
  right: 0;
  width: 85%;
  max-width: 600px;
  height: 100%;

  background-color: var(--bg-color);
}

.cart-mini-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  margin: 0 10px;

  border-bottom: 1px solid lightgray;
}

#cart_no {
  width: 20px;
  height: 20px;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -3px;
  left: 11px;

  z-index: 10;

  color: var(--footer-bg);

  font-size: var(--font-10) !important;
}
#cart_ {
  font-size: var(--font-10) !important;
}

.close-cart {
  width: 20%;

  display: flex;
  justify-content: center;
}

.close-cart-icon {
  /* margin: 5px; */

  /* position: relative; */
  /* right: 10px; */

  font-size: var(--font-28);
}

.cart-items {
  width: 100%;

  max-height: 45vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: rgb(252, 252, 252);
  padding: 10px;

  overflow: hidden;
  overflow-y: auto;
}

.cart-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid whitesmoke;
  border-top: 1px solid whitesmoke;

  padding: 10px;
  /* border-bottom: 1px solid lightgray; */
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-main {
  width: 50%;
  text-align: center;
}

.payment__title {
  width: 100%;
  margin: 50px 0 10px 0;
}

.payment__title p:nth-child(2) {
  font-size: 18px;
}

/* CART NOTIFICATION */

.no__item {
  padding: 100px;
}
.cart-notification {
  width: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 10px;
}

.cart-img-ad {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cart-item-row {
  width: 100%;
  display: flex;
  align-items: center;
}

.cart-ad-items {
  height: 300px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-ad-item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.img-modal-item {
  width: 40%;
  margin-right: 10px;

  border: 1px solid;
}
.cart-short-id {
  width: 55%;
}

/*  MAIN CART */
.cart-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.table-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin: 10px 0;
}

.cart-table {
  width: 70%;
}
.cart-table-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  visibility: visible;
}

.cart-table-header p {
  text-transform: capitalize;
}
.cart-table-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--footer-bg);
}
.cart-table-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 10px;
}

.cart-table-cell-main {
  width: 60%;

  display: flex;

  align-items: center;
}
.cart-table-cell {
  width: 20%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
}

.counter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  width: 50%;
  text-align: center;

  /* width: 150px; */
  height: 60px;

  padding: 5px;
}

.cart-items-header {
  display: none;
}

.cart-summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 10px;

  border-bottom: 1px solid var(--matt);
}

.cart-summary-item p {
  /* text-align: center; */
}

.cart-summary-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 489px) {
  .cart-table-cell-main {
    width: 100%;
  }
  .cart-table-cell {
    width: 40%;
  }

  .cart-items-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    z-index: 99;
    top: 0;
    padding: 10px;

    width: 100%;

    background-color: var(--footer-bg);
    color: var(--footer-text);
  }

  .right-checkout-details {
    padding: 0 !important;
  }
  .show-summary-btn {
    display: flex;
    align-items: center;
    color: var(--footer-text) !important;
  }

  .cart-summary-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;

    padding-bottom: 10px;
    margin-bottom: 10px;

    border-bottom: 1px solid var(--matt);
  }

  .cart-summary-item:last-child {
    border-bottom: none;
  }

  .mobile-show {
    display: none;
  }

  .cart-table-header {
    visibility: hidden;
  }
}

@media screen and (max-width: 572px) {
  .no__item {
    padding: 20px;
    margin: 50px 0;

    text-align: center;
  }
}

@media screen and (max-width: 999px) {
  .cart-table {
    width: 100%;
  }

  .cart-items-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    z-index: 99;
    top: 0;
    padding: 10px;

    width: 100%;

    background-color: var(--footer-bg);
    color: var(--footer-text);
  }

  .right-checkout-details {
    padding: 0 !important;
  }
  .show-summary-btn {
    display: flex;
    align-items: center;
    color: var(--footer-text) !important;
  }

  .cart-summary-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;

    padding-bottom: 10px;
    margin-bottom: 10px;

    border-bottom: 1px solid var(--matt);
  }

  .cart-summary-item:last-child {
    border-bottom: none;
  }

  .mobile-show {
    display: none;
  }

  .cart-table-header {
    visibility: hidden;
  }
}
