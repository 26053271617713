#landing_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  background-color: white !important;

  .header {
    width: 100%;
    max-width: 992px;
    height: 500px;
    overflow: hidden;

    border-radius: 20px;
    margin: 20px 0;
    border: 1px solid;

    .fg {
      width: 100%;
      height: 500px;
      overflow: hidden;
      .img-cover {
        width: 100%;
        height: 100%;

        object-fit: contain;
      }
    }
  }
  .body {
    max-width: 992px;
    padding: 10px;
  }

  @media screen and (max-width: 992px) {
    margin-top: 10px;
  }
}
