#hero {
  width: 100%;
  height: 100vh;

  margin-top: 50px;

  display: flex;
  align-items: center;

  /* border: 1px solid red; */
}

.hero__img {
  width: 45%;
  height: 100%;
}

.hero__details {
  width: 60%;
  height: 100%;

  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: var(--font-24);

  background-color: var(--color-smoothgreen) !important;
  color: var(--footer-text);
}

.hero__details h1 {
  padding: 0;
  font-size: 52pt !important;
  /* font-family: "Lobster Two", cursive !important; */
  /* font-family: "Unbounded", cursive !important; */

  /* text-align: center; */
  margin: 0;
  /* color: black !important; */

  width: 100%;
  /* line-height: 1.5; */
}
.hero__details p {
  font-size: 24pt;
}

#intro {
  width: 100%;
  padding: 150px 0;
  /* height: 500px; */

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--color-darkbrown);
}
.indent {
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 50px solid var(--color-smoothgreen);
  border-top: 20px solid transparent;
  display: inline-block;

  transform: rotate(180deg);

  position: absolute;
  top: -5px;
  /* border: 1px solid red; */
}
.intro-body {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--footer-text);
}

.intro-body p {
  font-size: 24pt !important;
  text-align: center;
  margin-bottom: 20px;
}

.intro-body a {
  font-size: 28px;
  font-weight: bold;
  text-decoration: underline;

  color: rgb(238, 231, 231);
}

/* SERVICE */
.service-cards {
  width: 100%;
  /* height: 500px; */

  display: flex;
  justify-content: space-around;
  align-items: center;

  position: relative;
}
.service-card {
  width: 35%;
  height: 504px;
  /* min-height: 550px; */

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  overflow: hidden !important;
}
.service-img {
  width: 100%;
  height: 100%;

  background-color: var(--footer-bg);
}

.service-filter {
  position: absolute;
  width: 33.5%;
  height: 504px;

  background-color: rgba(0, 0, 0, 0.667);
}
.service-details {
  position: absolute;

  width: 28%;
  padding: 15px;

  /* height: 300px; */

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  color: var(--footer-text);

  overflow: hidden;
}

.service-title-1 {
  font-family: "Cabin", sans-serif;
}
/* .service-title-2 {
  font-family: "Montserrat Alternates", sans-serif;
} */
.service-title-3 {
  /* font-family: "Pacifico", cursive; */
}
.service-details p {
  font-family: "Cabin", sans-serif;
}
.service-details p {
  font-family: "Montserrat Alternates", sans-serif;
}
/* .service-details p {
  font-family: "Pacifico", cursive;
} */

/* ABOUTME */
#about_me {
  background-color: white;
}
.container-flex {
  width: 100%;
  /* height: 600px; */
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 100px 0;
}
.about-me-img {
  width: 45%;
  height: 500px;
  border: none;

  border-radius: 5px;
  /* 
  border: 1px solid var(--color-darkolive);
  box-shadow: 10px 20px var(--color-darkolive); */

  overflow: hidden;
  /* border: 1px solid red; */
}
.about-me-details {
  width: 40%;
  /* height: 450px; */
  /* border: 1px solid red; */

  text-align: left;
}

#about__me-frame {
  width: 100%;
  height: 100%;
}

/* BLOG */
.blog-post-container {
  width: 100%;
  padding: 100px 0;

  background-color: var(--color-paleyellow);
}

.newsletter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-darkolive);
  color: var(--footer-text);
}
.blog-post-header {
  width: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 30px 10px;
}

.newsletter-header {
}

#newsletter_form {
  width: 50%;
}

.select__html {
  width: 100%;
  height: 100%;
  border: none !important;
  font-size: 16pt;
  background-color: transparent;
  color: white;
  padding: 0 10px 0 0;
}

select::-webkit-keygen-select {
  padding: 0 20px;
}

#newsletter_form input::placeholder {
  color: var(--footer-text);
}
#newsletter_form input {
  color: var(--footer-text);
  font-size: var(--font-16);
}

.blog-post-body {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: white;

  width: 30%;
  margin: 0 10px;

  height: 850px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.207);
}

.blog-post .header {
  width: 100%;
  height: 40%;

  /* border: 1px solid red */

  overflow: hidden;
}

.blog-post .body {
  width: 100%;
  height: 40%;
  padding: 15px;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  background-color: var(--footer-text);

  /* border: 1px solid red */

  overflow: hidden;
}

.blog-post .body h2 {
  font-size: 24pt;
}
.blog-post .body p {
  font-size: 16pt;
}
.blog-post .footer {
  width: 100%;
  height: 10%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--footer-text);

  font-size: 16pt !important;

  /* border: 1px solid red */

  overflow: hidden;
}

.blog-post .footer a {
  border: 1px solid black;
  padding: 15px;
  width: 50%;
  border-radius: 5px;
  margin-bottom: 10px;

  text-align: center;

  background-color: black;
  color: white;
}

/* PRODUCTS */
.featured-container {
  background-color: var(--color-smoothgreen);
}
.featured-product {
  height: 400px;
}

@media screen and (max-width: 992px) {
  #hero {
    margin-top: 0;
  }
  .hero__img {
    width: 40%;
    height: 100%;
  }
  .hero__details {
    width: 60%;

    font-size: var(--font-18);
  }
  .service-cards {
    flex-direction: column;
    align-items: center;
  }
  .service-card {
    width: 100%;
  }

  .service-details {
    width: 70%;
    height: 25%;
  }

  .service-filter {
    width: 100%;
  }

  .hero__details h1 {
    width: 95%;
    font-size: 38pt !important;
    line-height: 1.5;

    text-align: left;
  }

  #intro {
    padding: 100px 0;
  }
  .container-flex {
    flex-direction: column;
    align-items: center;

    /* height: 700px; */

    padding: 50px 0;
  }

  #newsletter_form {
    width: 90%;
  }

  .about-me-img {
    width: 95%;
    height: 456px;
    max-height: 500px;

    /* box-sizing: 20px 50px var(--color-paleyellow) !important; */
  }
  .about-me-details {
    width: 90%;
    margin-top: 20px;
  }

  .blog-post-body {
    flex-direction: column;
  }

  .blog-post {
    width: 100%;
    margin-bottom: 10px;
  }

  .featured-container {
    padding: 100px 0;
  }

  .featured-body {
    flex-direction: row;
    position: relative;
    height: 400px;
    /* padding: 100px 0; */
  }

  .featured-product {
    width: 80%;
    height: 100%;
    position: absolute;
  }
}

@media screen and (max-width: 572px) {
  #hero {
    flex-direction: column;
    height: 150vh;
  }
  .hero__img {
    width: 100%;
    height: 50%;
  }
  .hero__details {
    width: 100%;
    height: 50%;

    font-size: var(--font-18);
  }

  .service-details {
    width: 100%;
    height: 20%;
  }
}
