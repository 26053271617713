.img-thumbnail {
  width: 100px;
  height: 100px;

  margin-right: 10px;

  position: relative;

  /* border: 1px solid; */
}

.quantity_badge {
  width: 20px;
  height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--info-link);
  font-size: var(--font-10);
  font-weight: bold;
  color: var(--footer-text);
}

.img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.img-full {
  width: 100%;
  /* max-width: 500px; */
  height: 100%;

  object-fit: cover;
}

.img-contain {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.img-cover {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

#active_img {
  width: 100%;
  /* height: 430px; */

  display: flex;
  align-self: flex-start;
  /* align-items: center; */
  justify-content: space-between;

  position: relative;

  overflow: hidden;
}

.img-absolute {
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .img-full {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
