/* CART NOTIFICATION */

.no__item {
  padding: 100px;
}
.cart-notification {
  width: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 10px;
}

.cart-img-ad {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cart-item-row {
  width: 100%;
  display: flex;
  align-items: center;
}

.cart-ad-items {
  height: 300px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-ad-item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.img-modal-item {
  width: 40%;
  margin-right: 10px;

  border: 1px solid;
}
.cart-short-id {
  width: 55%;
}

/* END NOT */
