#blog__main {
  padding: 10px;
  margin-top: 100px;

  a {
    color: dodgerblue !important;
  }
  img {
    width: 100% !important;
    height: 400px;

    object-fit: contain !important;
  }

  li {
    list-style: inherit !important;
  }

  iframe {
    width: 50% !important;
    height: 500px;
    margin: 20px;

    display: flex;
    align-items: center;

    object-fit: contain !important;
  }

  @media screen and (max-width: 992px) {
    iframe {
      width: 100% !important;
      height: 500px;
      margin: 5px;

      display: flex;
      align-items: center;

      object-fit: contain !important;
    }
  }
}
