.container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.container-col {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-container {
  width: 60%;
  /* min-height: 100vh; */
  padding: 5px;

  box-sizing: border-box;

  /* border: 1px solid red; */
}
.right-container {
  width: 35%;
  /* min-height: 100vh; */
  padding: 15px;

  box-sizing: border-box;

  /* border: 1px solid red; */
}

@media screen and (max-width: 999px) {
  .product-wrapper {
    padding-top: 0px !important;
  }
  .container {
    flex-direction: column;
  }
  .left-container {
    width: 100%;

    padding: 0;
  }
  .right-container {
    width: 100%;
  }

  .row {
    align-items: flex-start;
    flex-direction: column;
    /* text-align: left; */
  }

  .by3-1 {
    width: 100%;

    justify-content: space-between;
  }
}
