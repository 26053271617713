.product {
  width: 32%;
  /* max-width: 300px; */
  /* height: 350px; */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  transition: box-shadow 0.3s;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  height: 550px;
  /* max-width: 500px; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: box-shadow 0.3s;
}

.product-card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%;
  height: 45%;
  /* height: 200px; */
  text-align: center;
  border-bottom: 1px solid #ccc;

  object-fit: contain;
}

.product-image img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.product-info {
  width: 80%;
  height: 55%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  /* justify-content: space-between; */
  text-align: center;
}

.product-info .title {
  font-size: 18px !important;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
}

.price {
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
}

.description {
  margin-top: 10px;
}

.size-selection {
  margin-top: 10px;
  /* width: 80%; */
  max-width: 200px;
  text-align: left;
  /* padding-left: 10px !important; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* border-left: 2px solid lightgray; */
}

.size-selection select {
  color: black !important;
  /* height: 25px; */
  font-size: 12pt;

  background-color: transparent !important;
  border: none !important;
}

.size-selection label {
  display: block;
  font-size: 14px;
  font-weight: bold;
}

.size-selection select {
  width: 100%;
  /* padding: 5px; */
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.product-card .add-to-cart {
  width: 100%;
  height: 55px !important;
  margin: 0;
  padding: 0;

  background-color: var(--footer-bg);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;
}

.product-card .add-to-cart:hover {
  background-color: #4caf50;
}

.product-card .add-to-cart .add-bag-btn {
  color: var(--footer-text) !important;
  font-size: var(--font-12);
}

/* .add-to-cart {
  margin-top: 10px;
  background-color: #4caf50;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  height: 50px !important;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-cart:hover {
  background-color: #45a049;
} */

@media screen and (max-width: 768px) {
  .product-card {
    width: 32%;
    max-width: 100%;
  }

  .product-info {
    width: 100%;
    gap: 0;
  }

  .product-info .title {
    font-size: 14pt !important;
    text-align: left;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 576px) {
  .product {
    width: 48% !important;
  }
  .product-card {
    width: 100% !important;
    max-width: 100%;
    height: 550px;
  }

  .product-image {
    height: 40%;
  }
  .product-info {
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}
