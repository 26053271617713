#pagination_el {
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center;
  align-content: center;
}
.paginate__btns {
  width: 40px;
  height: 40px;

  margin: 5px;

  border: 1px solid whitesmoke;
  border-radius: 5px;
}

.paginate__dots {
  display: flex;
  align-items: flex-end;
  height: 40px;
  margin: 1px;
}
